import gql from 'graphql-tag';
import * as Urql from '@urql/vue';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** Custom field stored as Json */
  AddonJson: { input: any; output: any; }
  /** ArbitraryObject */
  ArbitraryObject: { input: any; output: any; }
  /** clientCode for Typology */
  ClientCodeTypology: { input: any; output: any; }
  /** A custom scalar that handles emails */
  EmailAddress: { input: any; output: any; }
  /** Json */
  JSON: { input: any; output: any; }
  /** A 64-bit signed integer */
  Long: { input: any; output: any; }
};

export type AbstractScreening = {
  _id: Scalars['String']['output'];
  comment?: Maybe<Scalars['String']['output']>;
  date: Scalars['Int']['output'];
  documents: Array<Document>;
  status?: Maybe<Typology>;
};

export type AbstractUser = {
  _id: Scalars['ID']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  maidenName?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type AddContactInput = {
  contactId: Scalars['ID']['input'];
  contactType?: InputMaybe<Scalars['String']['input']>;
  contactTypes?: InputMaybe<Array<Scalars['ClientCodeTypology']['input']>>;
  hasAccessToReportingPower?: InputMaybe<Scalars['Boolean']['input']>;
  hasFileInputPower?: InputMaybe<Scalars['Boolean']['input']>;
  hasSignatoryPower?: InputMaybe<Scalars['Boolean']['input']>;
  notifyByEmailInvitation?: InputMaybe<Scalars['Boolean']['input']>;
  owner?: InputMaybe<Scalars['Boolean']['input']>;
  primary?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AddNewInvestorContactInput = {
  LinkedinId?: InputMaybe<Scalars['String']['input']>;
  PEP?: InputMaybe<PepInput>;
  _internalClientID?: InputMaybe<Scalars['String']['input']>;
  addonCharacteristics?: InputMaybe<Scalars['AddonJson']['input']>;
  address?: InputMaybe<Array<AddressInput>>;
  businessSector?: InputMaybe<Scalars['ClientCodeTypology']['input']>;
  children?: InputMaybe<Scalars['Int']['input']>;
  cityOfBirth?: InputMaybe<AddressInput>;
  civilStatus?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<CompanyInput>;
  contactTypes?: InputMaybe<Array<Scalars['ClientCodeTypology']['input']>>;
  dateOfBirth?: InputMaybe<Scalars['Int']['input']>;
  displayCurrency?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Array<EmailInput>>;
  facebookId?: InputMaybe<Scalars['String']['input']>;
  financialKnowledge?: InputMaybe<FinancialKnowledge>;
  firstName: Scalars['String']['input'];
  hasAccessToReportingPower?: InputMaybe<Scalars['Boolean']['input']>;
  hasFileInputPower?: InputMaybe<Scalars['Boolean']['input']>;
  hasSignatoryPower?: InputMaybe<Scalars['Boolean']['input']>;
  isOnboarded?: InputMaybe<Scalars['Boolean']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  lastInteractionDate?: InputMaybe<Scalars['Int']['input']>;
  lastLoginDatetime?: InputMaybe<Scalars['Int']['input']>;
  lastName: Scalars['String']['input'];
  maidenName?: InputMaybe<Scalars['String']['input']>;
  newsAlert?: InputMaybe<Scalars['Boolean']['input']>;
  notifyByEmailInvitation?: InputMaybe<Scalars['Boolean']['input']>;
  owner?: InputMaybe<Scalars['Boolean']['input']>;
  phone?: InputMaybe<Array<Scalars['String']['input']>>;
  profilePic?: InputMaybe<Scalars['String']['input']>;
  skypeId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<UserStatus>;
  taxResidence?: InputMaybe<Array<TaxResidenceInput>>;
  title?: InputMaybe<Scalars['String']['input']>;
  twitterId?: InputMaybe<Scalars['String']['input']>;
  whatsappId?: InputMaybe<Scalars['String']['input']>;
};

export type AddonCharacteristic = {
  __typename?: 'AddonCharacteristic';
  id: Scalars['String']['output'];
  label: Array<I18NLabel>;
};

export type AddonField = {
  __typename?: 'AddonField';
  _id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  path: Scalars['String']['output'];
  ref: Array<AddonFieldRef>;
  roles: Array<UserRoles>;
};

export enum AddonFieldRef {
  BusinessFacilitator = 'BusinessFacilitator',
  BusinessFacilitatorProspect = 'BusinessFacilitatorProspect',
  Distributor = 'Distributor',
  ExtendedProductPreSubscription = 'ExtendedProductPreSubscription',
  ExtendedUser = 'ExtendedUser',
  Investor = 'Investor',
  InvestorProspect = 'InvestorProspect',
  ProductPreSubscriptionSubscriber = 'ProductPreSubscriptionSubscriber',
  ProductSubscription = 'ProductSubscription',
  ProductSubscriptionPreSubscription = 'ProductSubscriptionPreSubscription',
  ProductSubscriptionSubscriber = 'ProductSubscriptionSubscriber'
}

export type Address = {
  __typename?: 'Address';
  formattedAddress: Scalars['String']['output'];
  googleComponents?: Maybe<Scalars['JSON']['output']>;
  googlePlaceId?: Maybe<Scalars['String']['output']>;
};

export type AddressInput = {
  formattedAddress: Scalars['String']['input'];
  googleComponents?: InputMaybe<Scalars['JSON']['input']>;
  googlePlaceId?: InputMaybe<Scalars['String']['input']>;
};

export type Advisor = Attachable & UserEntity & {
  __typename?: 'Advisor';
  _id: Scalars['ID']['output'];
  _internalClientID?: Maybe<Scalars['String']['output']>;
  active: Scalars['Boolean']['output'];
  addOnFields: Array<AddonField>;
  alerts?: Maybe<Array<Alert>>;
  businessFacilitator?: Maybe<BusinessFacilitator>;
  businessFacilitatorProspectsCount: Scalars['Int']['output'];
  collaboratorUsers: Array<BaseUser>;
  company: Company;
  contacts: Array<ExtendedUser>;
  distributor?: Maybe<Distributor>;
  distributors: Array<Distributor>;
  distributorsCount: Scalars['Int']['output'];
  financial: Financial;
  investor?: Maybe<Investor>;
  investorGroups: Array<InvestorGroup>;
  investorProspect?: Maybe<InvestorProspect>;
  investorProspects: Array<InvestorProspect>;
  investorProspectsCount: Scalars['Int']['output'];
  investorUsers: Array<ExtendedUser>;
  investors: Array<Investor>;
  investorsCount: Scalars['Int']['output'];
  kpi: Kpi;
  kycMeeting?: Maybe<KycMeeting>;
  name: Scalars['String']['output'];
  /** @deprecated Not used anymore */
  onboardingFile?: Maybe<OnboardingFile>;
  /** @deprecated Not used anymore */
  onboardingFiles: Array<OnboardingFile>;
  preSubscription?: Maybe<ProductPreSubscription>;
  preSubscriptionTaskGroupLabels: Array<WorkflowTasksGroupLabel>;
  preSubscriptions: Array<ProductPreSubscription>;
  preSubscriptionsCount: Scalars['Int']['output'];
  primaryContact?: Maybe<ExtendedUser>;
  productsLinkedToFiles: Array<SubscribableProduct>;
  prospectsCount: Scalars['Int']['output'];
  referredInvestors: Array<Investor>;
  referredInvestorsCount: Scalars['Int']['output'];
  referredInvestorsForSubscribedProduct: Array<InvestorSubscribedProduct>;
  referredInvestorsSubscribedProducts: Array<SubscribedProduct>;
  remediationFile?: Maybe<RemediationFile>;
  remediationFiles: Array<RemediationFile>;
  remediationFilesCount: Scalars['Int']['output'];
  statistics: Array<AdvisorStatistics>;
  subscribableProduct?: Maybe<SubscribableProduct>;
  subscribableProducts: Array<SubscribableProduct>;
  subscription?: Maybe<ProductSubscription>;
  subscriptionTaskGroupLabels: Array<WorkflowTasksGroupLabel>;
  subscriptions: Array<ProductSubscription>;
  subscriptionsCount: Scalars['Int']['output'];
  task?: Maybe<Task>;
  tasks: Array<Task>;
  totalBusinessFacilitatorOpportunityAmount: Scalars['Float']['output'];
  totalInvestorProspectOpportunityAmount: Scalars['Float']['output'];
  totalPreSubscribedAmount: Scalars['Float']['output'];
  totalProspectsOpportunityAmount: Scalars['Float']['output'];
  totalSubscribedAmount: Scalars['Float']['output'];
  type?: Maybe<Scalars['String']['output']>;
};


export type AdvisorAlertsArgs = {
  options?: InputMaybe<Options>;
  read?: InputMaybe<Scalars['Boolean']['input']>;
  users?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type AdvisorBusinessFacilitatorArgs = {
  id: Scalars['ID']['input'];
};


export type AdvisorContactsArgs = {
  signatory?: InputMaybe<Scalars['Boolean']['input']>;
};


export type AdvisorDistributorArgs = {
  id: Scalars['ID']['input'];
};


export type AdvisorDistributorsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Options>;
};


export type AdvisorDistributorsCountArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Options>;
};


export type AdvisorInvestorArgs = {
  id: Scalars['ID']['input'];
};


export type AdvisorInvestorProspectArgs = {
  id: Scalars['ID']['input'];
};


export type AdvisorInvestorProspectsArgs = {
  onlyActive?: InputMaybe<Scalars['Boolean']['input']>;
};


export type AdvisorInvestorUsersArgs = {
  invited?: InputMaybe<Scalars['Boolean']['input']>;
  withPrimaryEmail?: InputMaybe<Scalars['Boolean']['input']>;
};


export type AdvisorInvestorsArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  investorName?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Options>;
  types?: InputMaybe<Array<InvestorType>>;
};


export type AdvisorInvestorsCountArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  investorName?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Options>;
  types?: InputMaybe<Array<InvestorType>>;
};


export type AdvisorKycMeetingArgs = {
  id: Scalars['ID']['input'];
};


export type AdvisorOnboardingFileArgs = {
  id: Scalars['ID']['input'];
};


export type AdvisorPreSubscriptionArgs = {
  id: Scalars['ID']['input'];
};


export type AdvisorPreSubscriptionsArgs = {
  filter?: InputMaybe<ProductPreSubscriptionsFilterInput>;
  options?: InputMaybe<Options>;
};


export type AdvisorPreSubscriptionsCountArgs = {
  filter?: InputMaybe<ProductPreSubscriptionsFilterInput>;
  options?: InputMaybe<Options>;
};


export type AdvisorReferredInvestorsArgs = {
  investorName?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Options>;
};


export type AdvisorReferredInvestorsCountArgs = {
  investorName?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Options>;
};


export type AdvisorReferredInvestorsForSubscribedProductArgs = {
  at?: InputMaybe<Scalars['Int']['input']>;
  distributorId?: InputMaybe<Scalars['ID']['input']>;
  internalSubscriptionPortfolio?: InputMaybe<Scalars['Boolean']['input']>;
  main?: InputMaybe<Scalars['Boolean']['input']>;
  open?: InputMaybe<Scalars['Boolean']['input']>;
  underlyingId: Scalars['String']['input'];
};


export type AdvisorReferredInvestorsSubscribedProductsArgs = {
  at?: InputMaybe<Scalars['Int']['input']>;
  distributorId?: InputMaybe<Scalars['ID']['input']>;
  internalSubscriptionPortfolio?: InputMaybe<Scalars['Boolean']['input']>;
  main?: InputMaybe<Scalars['Boolean']['input']>;
  open?: InputMaybe<Scalars['Boolean']['input']>;
};


export type AdvisorRemediationFileArgs = {
  id: Scalars['ID']['input'];
};


export type AdvisorRemediationFilesArgs = {
  filters?: InputMaybe<RemediationFileFilterInput>;
  options?: InputMaybe<Options>;
};


export type AdvisorRemediationFilesCountArgs = {
  filters?: InputMaybe<RemediationFileFilterInput>;
  options?: InputMaybe<Options>;
};


export type AdvisorStatisticsArgs = {
  advisor?: InputMaybe<Scalars['ID']['input']>;
  at?: InputMaybe<Scalars['Int']['input']>;
  frequency?: InputMaybe<Period>;
  period?: InputMaybe<Period>;
};


export type AdvisorSubscribableProductArgs = {
  id: Scalars['ID']['input'];
};


export type AdvisorSubscribableProductsArgs = {
  investorId?: InputMaybe<Scalars['ID']['input']>;
  options?: InputMaybe<Options>;
};


export type AdvisorSubscriptionArgs = {
  id: Scalars['ID']['input'];
};


export type AdvisorSubscriptionsArgs = {
  filter?: InputMaybe<ProductSubscriptionsFilterInput>;
  options?: InputMaybe<Options>;
};


export type AdvisorSubscriptionsCountArgs = {
  filter?: InputMaybe<ProductSubscriptionsFilterInput>;
  options?: InputMaybe<Options>;
};


export type AdvisorTaskArgs = {
  id: Scalars['ID']['input'];
};


export type AdvisorTasksArgs = {
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  done?: InputMaybe<Scalars['Boolean']['input']>;
  investorId?: InputMaybe<Scalars['ID']['input']>;
  options?: InputMaybe<Options>;
};


export type AdvisorTotalPreSubscribedAmountArgs = {
  status?: InputMaybe<Array<PreSubscriptionStatus>>;
};


export type AdvisorTotalSubscribedAmountArgs = {
  status?: InputMaybe<Array<SubscriptionStatus>>;
};

export type AdvisorStatistics = DateRange & {
  __typename?: 'AdvisorStatistics';
  alerts: Array<AlertStatistics>;
  /**  Number of appointments whose status is ACCEPTED */
  appointments?: Maybe<Scalars['Int']['output']>;
  /**  Average number of pages viewed per session */
  avgPageViewsPerSession?: Maybe<Scalars['Int']['output']>;
  /**  Average session time in ms */
  avgSessionTime?: Maybe<Scalars['Int']['output']>;
  /**  Number of client connections */
  connections?: Maybe<Scalars['Int']['output']>;
  /**  Number of distinct content reads grouped by content */
  contentReads?: Maybe<Scalars['Int']['output']>;
  contents?: Maybe<ContentStatistics>;
  /**  Number of discussion threads */
  discussionThreads?: Maybe<Scalars['Int']['output']>;
  /**  The beginning date of the selected date range */
  from?: Maybe<Scalars['Int']['output']>;
  pageTraffic: Array<PageTraffic>;
  /**  The end date of the selected date range */
  to?: Maybe<Scalars['Int']['output']>;
};

export type AirQueries = {
  __typename?: 'AirQueries';
  topicClassification?: Maybe<TopicClassification>;
};


export type AirQueriesTopicClassificationArgs = {
  content: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type Alert = Notifiable & {
  __typename?: 'Alert';
  _id: Scalars['ID']['output'];
  attachments: Array<Attachable>;
  datetime: Scalars['Int']['output'];
  event?: Maybe<GenericEvent>;
  extra?: Maybe<Scalars['JSON']['output']>;
  favorite?: Maybe<Scalars['Boolean']['output']>;
  from?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  read?: Maybe<Scalars['Boolean']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  type?: Maybe<NotifiableType>;
};

export type AlertMutation = {
  __typename?: 'AlertMutation';
  favorite?: Maybe<Scalars['Boolean']['output']>;
  read?: Maybe<Scalars['Boolean']['output']>;
  reminder?: Maybe<Scalars['Boolean']['output']>;
};


export type AlertMutationFavoriteArgs = {
  favorite?: InputMaybe<Scalars['Boolean']['input']>;
};


export type AlertMutationReminderArgs = {
  days?: InputMaybe<Scalars['Int']['input']>;
};

export type AlertStatistics = {
  __typename?: 'AlertStatistics';
  count?: Maybe<Scalars['Int']['output']>;
  /**  Number of read alerts */
  readCount?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type AmountPerInvestor = {
  __typename?: 'AmountPerInvestor';
  distributionPerceived: Scalars['Float']['output'];
  grossValue: Scalars['Float']['output'];
  investors: Array<Investor>;
  netValue: Scalars['Float']['output'];
  totalAmountInvested: Scalars['Float']['output'];
  totalOfShares: Scalars['Float']['output'];
};

export type AmountPerMonth = {
  __typename?: 'AmountPerMonth';
  date: Scalars['Int']['output'];
  totalAmount: Scalars['Float']['output'];
};

export type AmountPerProduct = {
  __typename?: 'AmountPerProduct';
  percentageAmount: Scalars['Float']['output'];
  product: SubscribableProduct;
  totalAmount: Scalars['Float']['output'];
};

export type AmountPerUnderlying = {
  __typename?: 'AmountPerUnderlying';
  percentageAmount: Scalars['Float']['output'];
  totalAmount: Scalars['Float']['output'];
  underlying: Underlying;
};

export type AmountPerUnderlyingType = {
  __typename?: 'AmountPerUnderlyingType';
  percentageAmount: Scalars['Float']['output'];
  totalAmount: Scalars['Float']['output'];
  underlyingType: Scalars['String']['output'];
};

export type Appointment = Attachable & {
  __typename?: 'Appointment';
  _id: Scalars['ID']['output'];
  address?: Maybe<Address>;
  addressDetails?: Maybe<Scalars['String']['output']>;
  appointmentThread?: Maybe<Thread>;
  attachments: Array<Attachable>;
  confirmationEmail?: Maybe<AppointmentConfirmationEmail>;
  documents: Array<Document>;
  endDatetime?: Maybe<Scalars['Int']['output']>;
  icalId?: Maybe<Scalars['String']['output']>;
  interactionType?: Maybe<AppointmentInteractionType>;
  meetingReport?: Maybe<MeetingReport>;
  note?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  slotProposals: Array<TimeSlot>;
  startDatetime?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<AppointmentStatus>;
  subject?: Maybe<Scalars['String']['output']>;
  users: Array<ExtendedUser>;
};

export type AppointmentAttachmentInput = {
  id: Scalars['ID']['input'];
  typeName: Scalars['String']['input'];
};

export type AppointmentConfirmationEmail = {
  __typename?: 'AppointmentConfirmationEmail';
  emailSendDateTime?: Maybe<Scalars['Int']['output']>;
  emailSendRecipients?: Maybe<Array<BaseUser>>;
  icsUid?: Maybe<Scalars['String']['output']>;
};

export type AppointmentConfirmationEmailInput = {
  emailSendDateTime: Scalars['Int']['input'];
  emailSendRecipients: Array<Scalars['ID']['input']>;
  icsUid: Scalars['String']['input'];
};

export type AppointmentCreated = {
  __typename?: 'AppointmentCreated';
  appointment?: Maybe<Appointment>;
  timeslot?: Maybe<TimeSlotMutation>;
};

export type AppointmentInput = {
  address?: InputMaybe<AddressInput>;
  addressDetails?: InputMaybe<Scalars['String']['input']>;
  documents?: InputMaybe<Array<DocumentInput>>;
  endDatetime?: InputMaybe<Scalars['Int']['input']>;
  interactionType?: InputMaybe<AppointmentInteractionType>;
  note?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  startDatetime?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<AppointmentStatus>;
  subject: Scalars['String']['input'];
  users: Array<Scalars['ID']['input']>;
};

export enum AppointmentInteractionType {
  Phone = 'PHONE',
  Physical = 'PHYSICAL',
  VisioConference = 'VISIO_CONFERENCE'
}

export type AppointmentMutation = {
  __typename?: 'AppointmentMutation';
  addDocument?: Maybe<Appointment>;
  create?: Maybe<AppointmentCreated>;
  removeDocument?: Maybe<Appointment>;
  timeslot?: Maybe<TimeSlotMutation>;
  update?: Maybe<Appointment>;
};


export type AppointmentMutationAddDocumentArgs = {
  input: DocumentInput;
};


export type AppointmentMutationCreateArgs = {
  input: AppointmentInput;
};


export type AppointmentMutationRemoveDocumentArgs = {
  docId: Scalars['ID']['input'];
};


export type AppointmentMutationTimeslotArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type AppointmentMutationUpdateArgs = {
  input: AppointmentUpdateInput;
};

export enum AppointmentStatus {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Request = 'REQUEST'
}

export type AppointmentUpdateInput = {
  address?: InputMaybe<AddressInput>;
  addressDetails?: InputMaybe<Scalars['String']['input']>;
  attachments?: InputMaybe<Array<AppointmentAttachmentInput>>;
  confirmationEmail?: InputMaybe<AppointmentConfirmationEmailInput>;
  documents?: InputMaybe<Array<DocumentInput>>;
  endDatetime?: InputMaybe<Scalars['Int']['input']>;
  interactionType?: InputMaybe<AppointmentInteractionType>;
  meetingReport?: InputMaybe<MeetingReportInput>;
  note?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  startDatetime?: InputMaybe<Scalars['Int']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
};

export type AppointmentUpdated = {
  __typename?: 'AppointmentUpdated';
  appointment?: Maybe<Appointment>;
};

export type AssetValue = {
  __typename?: 'AssetValue';
  date: Scalars['Int']['output'];
  value: Scalars['Float']['output'];
};

export type Attachable = {
  _id: Scalars['ID']['output'];
};

export type BaseProductPreSubscription = ProductPreSubscription & {
  __typename?: 'BaseProductPreSubscription';
  _id: Scalars['ID']['output'];
  _internalClientID: Scalars['String']['output'];
  amount: Scalars['Float']['output'];
  lastRunningTaskDate: Scalars['Int']['output'];
};

export type BaseUser = AbstractUser & Attachable & {
  __typename?: 'BaseUser';
  _id: Scalars['ID']['output'];
  _internalClientID?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  maidenName?: Maybe<Scalars['String']['output']>;
  profilePic?: Maybe<Scalars['String']['output']>;
  status?: Maybe<UserStatus>;
  title?: Maybe<Scalars['String']['output']>;
};

export type BaseUserEntity = UserEntity & {
  __typename?: 'BaseUserEntity';
  _id: Scalars['ID']['output'];
  _internalClientID?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export enum Behaviour {
  Anxiety = 'ANXIETY'
}

export type BehaviourSignal = InsightPayload & {
  __typename?: 'BehaviourSignal';
  behaviour?: Maybe<Behaviour>;
  date?: Maybe<Scalars['Int']['output']>;
  probability?: Maybe<Scalars['Float']['output']>;
};

export type BusinessFacilitator = Attachable & UserEntity & {
  __typename?: 'BusinessFacilitator';
  _id: Scalars['ID']['output'];
  _internalClientID?: Maybe<Scalars['String']['output']>;
  active: Scalars['Boolean']['output'];
  addonCharacteristics?: Maybe<Scalars['AddonJson']['output']>;
  contacts: Array<ExtendedUser>;
  distributor: Distributor;
  name: Scalars['String']['output'];
  primaryContact?: Maybe<ExtendedUser>;
  type?: Maybe<Scalars['String']['output']>;
};


export type BusinessFacilitatorContactsArgs = {
  signatory?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BusinessOpportunity = {
  __typename?: 'BusinessOpportunity';
  amount?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nextStep?: Maybe<Scalars['String']['output']>;
  probability?: Maybe<Scalars['Float']['output']>;
  stage?: Maybe<Scalars['String']['output']>;
  targetDate?: Maybe<Scalars['Int']['output']>;
};

export type BusinessOpportunityInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nextStep?: InputMaybe<Scalars['String']['input']>;
  probability?: InputMaybe<Scalars['Float']['input']>;
  stage?: InputMaybe<Scalars['String']['input']>;
  targetDate?: InputMaybe<Scalars['Int']['input']>;
};

export type CanStartPreSubscriptionInput = {
  product: Scalars['ID']['input'];
  remote: Scalars['Boolean']['input'];
  subscribers: Array<Scalars['ID']['input']>;
};

export type CanStartSubscriptionInput = {
  distributionChannel: Array<Scalars['String']['input']>;
  platform?: InputMaybe<Scalars['String']['input']>;
  product: Scalars['ID']['input'];
  remote: Scalars['Boolean']['input'];
  subscribers: Array<Scalars['ID']['input']>;
};

export enum CertificateLevel {
  Advanced = 'advanced',
  Certified = 'certified',
  Simple = 'simple'
}

export type ClassificationFilter = {
  name?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type ClassificationNode = {
  __typename?: 'ClassificationNode';
  children: Array<ClassificationNode>;
  classificationName?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Scalars['ArbitraryObject']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type ClientConfig = {
  __typename?: 'ClientConfig';
  _id: Scalars['ID']['output'];
  clientId?: Maybe<Scalars['String']['output']>;
  /** deprecated use clientPortal on  AirFundConfig instead */
  clientPortal?: Maybe<Scalars['Boolean']['output']>;
  colors: Array<Color>;
  complyAdvantage?: Maybe<Scalars['Boolean']['output']>;
  contentKinds: Array<ContentKind>;
  defaultLocale?: Maybe<Scalars['String']['output']>;
  features?: Maybe<Array<Feature>>;
  instrClassification: Array<Scalars['String']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  managementEntityType?: Maybe<Scalars['String']['output']>;
  overridenStaticLabels?: Maybe<Scalars['ArbitraryObject']['output']>;
  permissions: Array<Permission>;
  reportLayoutConfig?: Maybe<Scalars['ArbitraryObject']['output']>;
  reportingJourney?: Maybe<Scalars['String']['output']>;
  routes: Array<Route>;
  sessionTime?: Maybe<Scalars['Int']['output']>;
  signature: ClientConfigSignature;
  theme?: Maybe<ClientConfigTheme>;
  viewsLayoutConfig?: Maybe<Scalars['ArbitraryObject']['output']>;
};

export type ClientConfigSignature = {
  __typename?: 'ClientConfigSignature';
  certifiedSigningEnabled: Scalars['Boolean']['output'];
  vendor: SigningVendor;
};

export type ClientConfigTheme = {
  __typename?: 'ClientConfigTheme';
  businessApplication?: Maybe<Scalars['JSON']['output']>;
  clientPortal?: Maybe<Scalars['JSON']['output']>;
};

export type CollectPerMonth = {
  __typename?: 'CollectPerMonth';
  date: Scalars['Int']['output'];
  totalAmount: Scalars['Float']['output'];
};

export type Color = {
  __typename?: 'Color';
  type?: Maybe<Scalars['String']['output']>;
  value: Array<Scalars['String']['output']>;
};

export type Comment = {
  __typename?: 'Comment';
  date?: Maybe<Scalars['Int']['output']>;
  sender?: Maybe<BaseUser>;
  text?: Maybe<Scalars['String']['output']>;
};

export type Company = {
  __typename?: 'Company';
  address?: Maybe<Address>;
  businessSector?: Maybe<Typology>;
  countryOfIncorporation?: Maybe<Typology>;
  email?: Maybe<Email>;
  logo?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  siretNumber?: Maybe<Scalars['String']['output']>;
  socialForm?: Maybe<Scalars['String']['output']>;
  socialObject?: Maybe<Scalars['String']['output']>;
  taxResidence?: Maybe<Array<TaxResidence>>;
  website?: Maybe<Scalars['String']['output']>;
};

export type CompanyInput = {
  address?: InputMaybe<AddressInput>;
  businessSector?: InputMaybe<Scalars['ClientCodeTypology']['input']>;
  countryOfIncorporation?: InputMaybe<Scalars['ClientCodeTypology']['input']>;
  email?: InputMaybe<EmailInput>;
  logo?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  siretNumber?: InputMaybe<Scalars['String']['input']>;
  socialForm?: InputMaybe<Scalars['String']['input']>;
  socialObject?: InputMaybe<Scalars['String']['input']>;
  taxResidence?: InputMaybe<Array<TaxResidenceInput>>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type ComplyAdvantageMonitoring = {
  __typename?: 'ComplyAdvantageMonitoring';
  hits: Scalars['Int']['output'];
  newHit: Scalars['Boolean']['output'];
  searchId: Scalars['String']['output'];
};

export type ComplyAdvantageScreening = AbstractScreening & {
  __typename?: 'ComplyAdvantageScreening';
  _id: Scalars['String']['output'];
  clientId?: Maybe<Scalars['String']['output']>;
  clientRef: Scalars['String']['output'];
  comment?: Maybe<Scalars['String']['output']>;
  date: Scalars['Int']['output'];
  documents: Array<Document>;
  entityId?: Maybe<Scalars['String']['output']>;
  fuzziness: Scalars['Float']['output'];
  isMonitored?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated To remove */
  searchId: Scalars['String']['output'];
  status?: Maybe<Typology>;
};

export type ConnectedUser = AbstractUser & Attachable & {
  __typename?: 'ConnectedUser';
  LinkedinId?: Maybe<Scalars['String']['output']>;
  _id: Scalars['ID']['output'];
  _internalClientID?: Maybe<Scalars['String']['output']>;
  address: Array<Address>;
  advisor: ExtendedUser;
  alerts: Array<Alert>;
  appointment?: Maybe<Appointment>;
  appointments: Array<Appointment>;
  children?: Maybe<Scalars['Int']['output']>;
  cityOfBirth?: Maybe<Address>;
  civilStatus?: Maybe<Scalars['String']['output']>;
  connections: Array<ConnectionInformations>;
  contacts: Array<ExtendedUser>;
  content?: Maybe<ContentStatus>;
  contents: Array<ContentStatus>;
  dateOfBirth?: Maybe<Scalars['Int']['output']>;
  displayCurrency?: Maybe<Scalars['String']['output']>;
  documentRequest?: Maybe<DocumentRequest>;
  documentRequests: Array<DocumentRequest>;
  email: Array<Email>;
  facebookId?: Maybe<Scalars['String']['output']>;
  financial: Financial;
  financialKnowledge?: Maybe<FinancialKnowledge>;
  firstName?: Maybe<Scalars['String']['output']>;
  hasDigitalIdentityValidationSession: Scalars['Boolean']['output'];
  investor?: Maybe<Investor>;
  investorByAfId?: Maybe<Investor>;
  investors: Array<Investor>;
  isOnboarded?: Maybe<Scalars['Boolean']['output']>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  kycExpirationDate: Scalars['Int']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  maidenName?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  newsAlert?: Maybe<Scalars['Boolean']['output']>;
  notificationConfig: Array<NotificationConfig>;
  notifications: Array<Notification>;
  opinions: Array<Opinion>;
  phone: Array<Scalars['String']['output']>;
  preSubscription?: Maybe<ProductPreSubscription>;
  preSubscriptions: Array<ProductPreSubscription>;
  primaryContact?: Maybe<ExtendedUser>;
  productGuide: ProductGuide;
  profilePic?: Maybe<Scalars['String']['output']>;
  redirectionSession: SigningRedirectionSession;
  rgpdAgreed: Scalars['Boolean']['output'];
  roles: Array<Scalars['String']['output']>;
  skypeId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<UserStatus>;
  subscription?: Maybe<ProductSubscription>;
  subscriptions: Array<ProductSubscription>;
  thread?: Maybe<Thread>;
  threads: Array<Thread>;
  timeslots: Array<TimeSlot>;
  title?: Maybe<Scalars['String']['output']>;
  twitterId?: Maybe<Scalars['String']['output']>;
  userUniverse: Array<UserLink>;
  whatsappId?: Maybe<Scalars['String']['output']>;
};


export type ConnectedUserAlertsArgs = {
  options?: InputMaybe<Options>;
  read?: InputMaybe<Scalars['Boolean']['input']>;
  users?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type ConnectedUserAppointmentArgs = {
  id: Scalars['ID']['input'];
};


export type ConnectedUserAppointmentsArgs = {
  from?: InputMaybe<RangeDate>;
  options?: InputMaybe<Options>;
  status?: InputMaybe<AppointmentStatus>;
  to?: InputMaybe<RangeDate>;
};


export type ConnectedUserConnectionsArgs = {
  options?: InputMaybe<Options>;
};


export type ConnectedUserContactsArgs = {
  signatory?: InputMaybe<Scalars['Boolean']['input']>;
};


export type ConnectedUserContentArgs = {
  id: Scalars['ID']['input'];
};


export type ConnectedUserContentsArgs = {
  options?: InputMaybe<Options>;
};


export type ConnectedUserDocumentRequestArgs = {
  id: Scalars['ID']['input'];
};


export type ConnectedUserDocumentRequestsArgs = {
  options?: InputMaybe<Options>;
  status?: InputMaybe<DocumentRequestStatus>;
};


export type ConnectedUserInvestorArgs = {
  id: Scalars['ID']['input'];
};


export type ConnectedUserInvestorByAfIdArgs = {
  id: Scalars['String']['input'];
};


export type ConnectedUserInvestorsArgs = {
  types?: InputMaybe<Array<InvestorType>>;
};


export type ConnectedUserPreSubscriptionArgs = {
  id: Scalars['ID']['input'];
};


export type ConnectedUserPreSubscriptionsArgs = {
  productId?: InputMaybe<Scalars['ID']['input']>;
};


export type ConnectedUserRedirectionSessionArgs = {
  transactionId: Scalars['String']['input'];
};


export type ConnectedUserSubscriptionArgs = {
  id: Scalars['ID']['input'];
};


export type ConnectedUserSubscriptionsArgs = {
  productId?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<Array<SubscriptionStatus>>;
};


export type ConnectedUserThreadArgs = {
  id: Scalars['ID']['input'];
};


export type ConnectedUserThreadsArgs = {
  options?: InputMaybe<Options>;
};


export type ConnectedUserTimeslotsArgs = {
  from?: InputMaybe<RangeDate>;
  status?: InputMaybe<TimeSlotStatus>;
  to?: InputMaybe<RangeDate>;
};

export type ConnectionInformations = {
  __typename?: 'ConnectionInformations';
  loginDate?: Maybe<Scalars['Int']['output']>;
  sessionDuration?: Maybe<Scalars['Int']['output']>;
};

export type ConnectionValue = {
  __typename?: 'ConnectionValue';
  date?: Maybe<Scalars['Int']['output']>;
  sessionDuration?: Maybe<Scalars['Int']['output']>;
};

export type Content = Attachable & {
  __typename?: 'Content';
  _id: Scalars['ID']['output'];
  authors: Array<ContentAuthor>;
  averageReadTime?: Maybe<Scalars['Int']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  contentKind: Scalars['String']['output'];
  cosineSimilarity: Array<Content>;
  date?: Maybe<Scalars['Int']['output']>;
  headings?: Maybe<Array<Scalars['String']['output']>>;
  hotLabels: Array<Label>;
  image?: Maybe<Scalars['String']['output']>;
  investorsMetrics: Array<InvestorMetric>;
  lang?: Maybe<Scalars['String']['output']>;
  preview?: Maybe<Scalars['String']['output']>;
  publicationDate?: Maybe<Scalars['Int']['output']>;
  tags: Array<ContentTag>;
  title?: Maybe<Scalars['String']['output']>;
  userInterest: Array<UserInterest>;
  validityDate?: Maybe<Scalars['Int']['output']>;
  video?: Maybe<Scalars['Boolean']['output']>;
};

export type ContentAuthor = {
  __typename?: 'ContentAuthor';
  email?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type ContentCategory = {
  __typename?: 'ContentCategory';
  _id: Scalars['ID']['output'];
  highlight?: Maybe<Scalars['Boolean']['output']>;
  kind: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type ContentGroup = LabelGroup & {
  __typename?: 'ContentGroup';
  _id: Scalars['ID']['output'];
  entrypoint?: Maybe<Scalars['Boolean']['output']>;
  kind: Scalars['String']['output'];
  labels: Array<LabelGroupLabel>;
  name?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
};

export type ContentKind = {
  __typename?: 'ContentKind';
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  label: Array<I18NLabel>;
  universeLabels: Array<Scalars['ID']['output']>;
};

export type ContentMutation = {
  __typename?: 'ContentMutation';
  markBookmark?: Maybe<Content>;
  markRead?: Maybe<Scalars['Boolean']['output']>;
  markUseful?: Maybe<Content>;
};


export type ContentMutationMarkBookmarkArgs = {
  bookmarked?: InputMaybe<Scalars['Boolean']['input']>;
};


export type ContentMutationMarkUsefulArgs = {
  useful?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ContentResult = SearchResult & {
  __typename?: 'ContentResult';
  _id: Scalars['ID']['output'];
  averageReadTime?: Maybe<Scalars['Int']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  index?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['String']['output']>;
  source?: Maybe<ContentSource>;
};

export type ContentSource = {
  __typename?: 'ContentSource';
  authors: Array<ContentAuthor>;
  date?: Maybe<Scalars['Int']['output']>;
  kind?: Maybe<Scalars['String']['output']>;
  tags: Array<ContentTag>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ContentStatistics = {
  __typename?: 'ContentStatistics';
  created?: Maybe<Scalars['Int']['output']>;
  read?: Maybe<Scalars['Int']['output']>;
  sent?: Maybe<Scalars['Int']['output']>;
};

export type ContentStatus = {
  __typename?: 'ContentStatus';
  content?: Maybe<Content>;
  openDiscussion?: Maybe<Scalars['Boolean']['output']>;
  read?: Maybe<Scalars['Boolean']['output']>;
  readDate?: Maybe<Scalars['Int']['output']>;
  sendDate?: Maybe<Scalars['Int']['output']>;
  useful?: Maybe<Scalars['Boolean']['output']>;
};

export type ContentTag = Tag & {
  __typename?: 'ContentTag';
  date?: Maybe<Scalars['Int']['output']>;
  label?: Maybe<Label>;
};

export type Contribution = {
  __typename?: 'Contribution';
  _id: Scalars['ID']['output'];
  bucketCode?: Maybe<Scalars['String']['output']>;
  bucketName?: Maybe<Scalars['String']['output']>;
  calculationDate?: Maybe<Scalars['Int']['output']>;
  classificationCode?: Maybe<Scalars['String']['output']>;
  currencyCode?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['Int']['output']>;
  netReturn?: Maybe<Scalars['Float']['output']>;
  periodCode?: Maybe<Period>;
};

export type CreateUserInput = {
  LinkedinId?: InputMaybe<Scalars['String']['input']>;
  PEP?: InputMaybe<PepInput>;
  _internalClientID?: InputMaybe<Scalars['String']['input']>;
  addonCharacteristics?: InputMaybe<Scalars['AddonJson']['input']>;
  address?: InputMaybe<Array<AddressInput>>;
  businessSector?: InputMaybe<Scalars['ClientCodeTypology']['input']>;
  children?: InputMaybe<Scalars['Int']['input']>;
  cityOfBirth?: InputMaybe<AddressInput>;
  civilStatus?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<CompanyInput>;
  contactTypes?: InputMaybe<Array<Scalars['ClientCodeTypology']['input']>>;
  dateOfBirth?: InputMaybe<Scalars['Int']['input']>;
  displayCurrency?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Array<EmailInput>>;
  facebookId?: InputMaybe<Scalars['String']['input']>;
  financialKnowledge?: InputMaybe<FinancialKnowledge>;
  firstName: Scalars['String']['input'];
  isOnboarded?: InputMaybe<Scalars['Boolean']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  lastInteractionDate?: InputMaybe<Scalars['Int']['input']>;
  lastLoginDatetime?: InputMaybe<Scalars['Int']['input']>;
  lastName: Scalars['String']['input'];
  maidenName?: InputMaybe<Scalars['String']['input']>;
  newsAlert?: InputMaybe<Scalars['Boolean']['input']>;
  phone?: InputMaybe<Array<Scalars['String']['input']>>;
  profilePic?: InputMaybe<Scalars['String']['input']>;
  skypeId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<UserStatus>;
  taxResidence?: InputMaybe<Array<TaxResidenceInput>>;
  title?: InputMaybe<Scalars['String']['input']>;
  twitterId?: InputMaybe<Scalars['String']['input']>;
  whatsappId?: InputMaybe<Scalars['String']['input']>;
};

export type DateRange = {
  /**  The beginning date of the selected date range */
  from?: Maybe<Scalars['Int']['output']>;
  /**  The end date of the selected date range */
  to?: Maybe<Scalars['Int']['output']>;
};

export type DateRangeInput = {
  endDate?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['Int']['input']>;
};

export type DeviceToken = {
  __typename?: 'DeviceToken';
  token?: Maybe<Scalars['String']['output']>;
};

export type DeviceTokenInput = {
  token?: InputMaybe<Scalars['String']['input']>;
};

export type DigitalIdentityChecking = {
  __typename?: 'DigitalIdentityChecking';
  isExactMatching: Scalars['Boolean']['output'];
  matchingResults: Array<MatchingDigitalIdentity>;
};

export type DistributionChannel = {
  __typename?: 'DistributionChannel';
  suppliers: Array<Supplier>;
};

export type Distributor = Attachable & {
  __typename?: 'Distributor';
  _id: Scalars['ID']['output'];
  _internalClientID?: Maybe<Scalars['String']['output']>;
  addonCharacteristics?: Maybe<Scalars['AddonJson']['output']>;
  collaborators: Array<BusinessFacilitator>;
  company: Company;
  createdBy?: Maybe<Reference>;
  createdDate?: Maybe<Scalars['Long']['output']>;
  customSubscriptionDocumentChecklist: Array<OnboardingDocument>;
  documents: Array<Document>;
  legalRepresentatives: Array<BusinessFacilitator>;
  master: Scalars['Boolean']['output'];
  masterDistributor?: Maybe<Distributor>;
  /**
   *  masterDistributor(id: ID!): Distributor
   *  masterDistributors: [Distributor!]!
   *  referred distributor associated to an advisor filtered by "id"
   */
  referredDistributor?: Maybe<Distributor>;
  /**  referred distributors associated to an advisor */
  referredDistributors: Array<Distributor>;
  referredInvestor?: Maybe<Investor>;
  referredInvestors: Array<Investor>;
  referredInvestorsCount: Scalars['Int']['output'];
  subscribedProducts: Array<InvestorSubscribedProduct>;
  tiering?: Maybe<Scalars['Int']['output']>;
  totalAum: AssetValue;
};


export type DistributorCustomSubscriptionDocumentChecklistArgs = {
  investorType: Scalars['ClientCodeTypology']['input'];
};


export type DistributorReferredDistributorArgs = {
  id: Scalars['ID']['input'];
};


export type DistributorReferredDistributorsArgs = {
  options?: InputMaybe<Options>;
};


export type DistributorReferredInvestorArgs = {
  id: Scalars['ID']['input'];
};


export type DistributorReferredInvestorsArgs = {
  investorName?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Options>;
};


export type DistributorReferredInvestorsCountArgs = {
  investorName?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Options>;
};


export type DistributorSubscribedProductsArgs = {
  at?: InputMaybe<Scalars['Int']['input']>;
};

export type DistributorLink = {
  __typename?: 'DistributorLink';
  _id: Scalars['ID']['output'];
  distributor: Distributor;
  referredDistributor: Distributor;
};

export type Document = Attachable & {
  __typename?: 'Document';
  _id: Scalars['ID']['output'];
  audit: Array<DocumentAudit>;
  certificateLevel: CertificateLevel;
  expirationDate?: Maybe<Scalars['Int']['output']>;
  fileType?: Maybe<Scalars['String']['output']>;
  hash: Scalars['String']['output'];
  isSignaturesCompleted: Scalars['Boolean']['output'];
  lang?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  publicationDate?: Maybe<Scalars['Int']['output']>;
  sender?: Maybe<BaseUser>;
  signature?: Maybe<UniversignSignature>;
  signatures?: Maybe<Array<UniversignSignature>>;
  /**  signing positions matching signing strategy of the connected user */
  signingBlocks: Array<SigningBlock>;
  size?: Maybe<Scalars['Int']['output']>;
  source?: Maybe<DocumentSource>;
  status?: Maybe<DocumentStatus>;
  subtype?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Typology>;
  url?: Maybe<Scalars['String']['output']>;
};


export type DocumentSigningBlocksArgs = {
  allBlocks?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DocumentAudit = {
  __typename?: 'DocumentAudit';
  date: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  reason?: Maybe<Scalars['String']['output']>;
  status: DocumentStatus;
  user: ExtendedUser;
  userEntity: UserEntity;
};

export type DocumentInput = {
  _id?: InputMaybe<Scalars['String']['input']>;
  expirationDate?: InputMaybe<Scalars['Int']['input']>;
  fileType?: InputMaybe<Scalars['String']['input']>;
  hash: Scalars['String']['input'];
  name: Scalars['String']['input'];
  publicationDate?: InputMaybe<Scalars['Int']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['ClientCodeTypology']['input']>;
};

export type DocumentMutation = {
  __typename?: 'DocumentMutation';
  generate?: Maybe<Document>;
  /** @deprecated Will be deleted */
  generateByTemplate: Array<Document>;
  reject: Document;
  /** @deprecated not used anymore */
  signingTransaction?: Maybe<Document>;
  update?: Maybe<Document>;
  updateAudit: DocumentAudit;
  validate: Document;
};


export type DocumentMutationGenerateArgs = {
  input: GenerateDocumentInput;
};


export type DocumentMutationGenerateByTemplateArgs = {
  input?: InputMaybe<GenerateByWorkflowAndTypesInput>;
};


export type DocumentMutationRejectArgs = {
  reason: Scalars['String']['input'];
};


export type DocumentMutationUpdateArgs = {
  input: UpdateDocumentInput;
};


export type DocumentMutationUpdateAuditArgs = {
  id: Scalars['ID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
  status: DocumentStatus;
};


export type DocumentMutationValidateArgs = {
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type DocumentRequest = Attachable & {
  __typename?: 'DocumentRequest';
  _id: Scalars['ID']['output'];
  creationDate?: Maybe<Scalars['Int']['output']>;
  message: Array<MessageRequest>;
  name?: Maybe<Scalars['String']['output']>;
  sender: BaseUser;
  status: DocumentRequestStatus;
  type: Typology;
};

export type DocumentRequestInput = {
  investorId: Scalars['ID']['input'];
  message: Scalars['String']['input'];
  name: Scalars['String']['input'];
  type: Scalars['ClientCodeTypology']['input'];
};

export type DocumentRequestMutation = {
  __typename?: 'DocumentRequestMutation';
  create?: Maybe<DocumentRequest>;
  sendMessage?: Maybe<DocumentRequest>;
  update?: Maybe<DocumentRequest>;
  updateStatus?: Maybe<DocumentRequest>;
};


export type DocumentRequestMutationCreateArgs = {
  input: DocumentRequestInput;
};


export type DocumentRequestMutationSendMessageArgs = {
  message: Scalars['String']['input'];
  requestId: Scalars['ID']['input'];
};


export type DocumentRequestMutationUpdateArgs = {
  input: UpdateDocumentRequestInput;
  requestId: Scalars['ID']['input'];
};


export type DocumentRequestMutationUpdateStatusArgs = {
  requestId: Scalars['ID']['input'];
  status?: InputMaybe<DocumentRequestStatus>;
};

export enum DocumentRequestStatus {
  Delete = 'DELETE',
  Done = 'DONE',
  Pending = 'PENDING'
}

export enum DocumentSource {
  Airfund = 'AIRFUND',
  Airwealth = 'AIRWEALTH'
}

export enum DocumentStatus {
  Closed = 'CLOSED',
  Creating = 'CREATING',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  Reminder = 'REMINDER',
  SignatureInPreparation = 'SIGNATURE_IN_PREPARATION',
  SignatureToSchedule = 'SIGNATURE_TO_SCHEDULE',
  Signed = 'SIGNED',
  ToGenerate = 'TO_GENERATE',
  ToSign = 'TO_SIGN',
  Unrecognized = 'UNRECOGNIZED',
  Validated = 'VALIDATED'
}

export type DocumentTemplate = {
  __typename?: 'DocumentTemplate';
  _id: Scalars['ID']['output'];
  footer?: Maybe<DocumentTemplateFooter>;
  header?: Maybe<DocumentTemplateHeader>;
  name?: Maybe<Scalars['String']['output']>;
  sections: Array<DocumentTemplateSection>;
};

export type DocumentTemplateFooter = {
  __typename?: 'DocumentTemplateFooter';
  pageNumber?: Maybe<Scalars['Boolean']['output']>;
  pageNumberX?: Maybe<Scalars['Float']['output']>;
  pageNumberY?: Maybe<Scalars['Float']['output']>;
};

export type DocumentTemplateHeader = {
  __typename?: 'DocumentTemplateHeader';
  logo?: Maybe<Scalars['String']['output']>;
  logoX?: Maybe<Scalars['Float']['output']>;
  logoY?: Maybe<Scalars['Float']['output']>;
};

export type DocumentTemplateSection = {
  __typename?: 'DocumentTemplateSection';
  id: Scalars['String']['output'];
  payload: Scalars['JSON']['output'];
  type: Scalars['String']['output'];
};

export type Email = {
  __typename?: 'Email';
  email: Scalars['EmailAddress']['output'];
  type: Scalars['String']['output'];
};

export type EmailInput = {
  email: Scalars['EmailAddress']['input'];
  type: Scalars['String']['input'];
};

export type EntityAudit = {
  __typename?: 'EntityAudit';
  _id: Scalars['String']['output'];
  newValue: Scalars['String']['output'];
  oldValue: Scalars['String']['output'];
  path: Scalars['String']['output'];
  pathLabel: Scalars['String']['output'];
  timestamp: Scalars['Int']['output'];
  user?: Maybe<ExtendedUser>;
};

export type EntitySelector = {
  ids: Array<Scalars['ID']['input']>;
  mode: EntitySelectorMode;
};

export enum EntitySelectorMode {
  All = 'ALL',
  Any = 'ANY'
}

export type ExtendedProductPreSubscription = Attachable & ProductPreSubscription & {
  __typename?: 'ExtendedProductPreSubscription';
  _externalClientID?: Maybe<Scalars['String']['output']>;
  _id: Scalars['ID']['output'];
  _internalClientID: Scalars['String']['output'];
  acaCommitmentRatio: Scalars['Float']['output'];
  addonCharacteristics?: Maybe<Scalars['AddonJson']['output']>;
  advisor: UserEntity;
  amount: Scalars['Float']['output'];
  coSubscriptionDocumentChecklist: Array<OnboardingDocument>;
  /**  contacts involved in the subscription file process (editing and signing users) */
  contacts: Array<ProductPreSubscriptionContact>;
  date?: Maybe<Scalars['Int']['output']>;
  distributorDocuments: Array<Document>;
  documentChecklist: Array<OnboardingDocument>;
  handWrittenAmount?: Maybe<Scalars['String']['output']>;
  handWrittenNote?: Maybe<Scalars['String']['output']>;
  integratedIntoSubscription: Scalars['Boolean']['output'];
  invitationNote?: Maybe<Scalars['String']['output']>;
  lastRunningTaskDate: Scalars['Int']['output'];
  numberOfShares: Scalars['Float']['output'];
  product: SubscribableProduct;
  status: PreSubscriptionStatus;
  subscriberAddonCharacteristics?: Maybe<Scalars['AddonJson']['output']>;
  subscribers: Array<ProductSubscriptionSubscriber>;
  subscriptionDocuments: Array<SubscriptionDocument>;
  support?: Maybe<SubscriptionSupport>;
  workflow: Workflow;
};


export type ExtendedProductPreSubscriptionSubscriberAddonCharacteristicsArgs = {
  subscriberId: Scalars['ID']['input'];
};

export type ExtendedUser = AbstractUser & ScreeningSubject & {
  __typename?: 'ExtendedUser';
  LinkedinId?: Maybe<Scalars['String']['output']>;
  PEP?: Maybe<Pep>;
  _id: Scalars['ID']['output'];
  _internalClientID?: Maybe<Scalars['String']['output']>;
  active: Scalars['Boolean']['output'];
  addonCharacteristics?: Maybe<Scalars['AddonJson']['output']>;
  address: Array<Address>;
  behaviourSignals?: Maybe<Array<BehaviourSignal>>;
  businessSector?: Maybe<Typology>;
  children?: Maybe<Scalars['Int']['output']>;
  cityOfBirth?: Maybe<Address>;
  civilStatus?: Maybe<Scalars['String']['output']>;
  connections: Array<ConnectionInformations>;
  contactType?: Maybe<Scalars['String']['output']>;
  contactTypes: Array<Typology>;
  dateOfBirth?: Maybe<Scalars['Int']['output']>;
  displayCurrency?: Maybe<Scalars['String']['output']>;
  documents: Array<Document>;
  email: Array<Email>;
  facebookId?: Maybe<Scalars['String']['output']>;
  financialKnowledge?: Maybe<FinancialKnowledge>;
  firstName?: Maybe<Scalars['String']['output']>;
  hasAccessToReportingPower: Scalars['Boolean']['output'];
  hasDigitalIdentityValidationSession: Scalars['Boolean']['output'];
  hasFileInputPower: Scalars['Boolean']['output'];
  hasSignatoryPower: Scalars['Boolean']['output'];
  initialInvitationEmailSendDate?: Maybe<Scalars['Int']['output']>;
  insights: Array<Insight>;
  investors: Array<Investor>;
  /** deprecated use checkCanAddNewContact(email: String!) instead */
  isAlreadyLinkedToAnAdvisor?: Maybe<Scalars['Boolean']['output']>;
  /** deprecated use checkCanAddNewContact(email: String!) instead */
  isAlreadyOwnerContactAtMyCompany?: Maybe<Scalars['Boolean']['output']>;
  isOnboarded: Scalars['Boolean']['output'];
  jobTitle?: Maybe<Scalars['String']['output']>;
  lastInteractionDate?: Maybe<Scalars['Int']['output']>;
  lastInvitationEmailSendDate?: Maybe<Scalars['Int']['output']>;
  lastLoginDatetime?: Maybe<Scalars['Int']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  maidenName?: Maybe<Scalars['String']['output']>;
  newsAlert?: Maybe<Scalars['Boolean']['output']>;
  owner: Scalars['Boolean']['output'];
  phone: Array<Scalars['String']['output']>;
  primary: Scalars['Boolean']['output'];
  profilePic?: Maybe<Scalars['String']['output']>;
  rgpdAgreed: Scalars['Boolean']['output'];
  roles: Array<Maybe<Scalars['String']['output']>>;
  signing: Signing;
  skypeId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<UserStatus>;
  taxResidence?: Maybe<Array<TaxResidence>>;
  title?: Maybe<Scalars['String']['output']>;
  twitterId?: Maybe<Scalars['String']['output']>;
  whatsappId?: Maybe<Scalars['String']['output']>;
};


export type ExtendedUserConnectionsArgs = {
  options?: InputMaybe<Options>;
};


export type ExtendedUserDocumentsArgs = {
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type Feature = {
  __typename?: 'Feature';
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
};

export type Fee = {
  __typename?: 'Fee';
  commissions: Array<FeeCommission>;
  date?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<FeeCommission>;
};

export type FeeCommission = {
  __typename?: 'FeeCommission';
  ratio?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export enum FeeSource {
  AirFund = 'AirFund',
  Calculated = 'Calculated',
  Import = 'Import',
  Manual = 'Manual'
}

export enum FeeStatus {
  Cancelled = 'Cancelled',
  Paid = 'Paid',
  ToBePaid = 'ToBePaid'
}

export enum FeeType {
  Premium = 'Premium',
  SubscriptionFee = 'SubscriptionFee'
}

export type Filter = {
  field: Scalars['String']['input'];
  op: FilterOp;
  value: Scalars['String']['input'];
};

export enum FilterOp {
  Eq = 'EQ',
  Like = 'LIKE'
}

export type Financial = {
  __typename?: 'Financial';
  aum: AssetValue;
  positions: Array<StatisticPosition>;
  totalAum: AssetValue;
  totalBfAum: AssetValue;
  totalBfInvestors: Scalars['Int']['output'];
  totalInvestors: Scalars['Int']['output'];
};


export type FinancialAumArgs = {
  at?: InputMaybe<Scalars['Int']['input']>;
  investorId?: InputMaybe<Scalars['ID']['input']>;
};


export type FinancialPositionsArgs = {
  at?: InputMaybe<Scalars['Int']['input']>;
  investorId?: InputMaybe<Scalars['ID']['input']>;
};


export type FinancialTotalAumArgs = {
  at?: InputMaybe<Scalars['Int']['input']>;
};


export type FinancialTotalBfAumArgs = {
  at?: InputMaybe<Scalars['Int']['input']>;
};


export type FinancialTotalBfInvestorsArgs = {
  at?: InputMaybe<Scalars['Int']['input']>;
};


export type FinancialTotalInvestorsArgs = {
  at?: InputMaybe<Scalars['Int']['input']>;
};

export enum FinancialKnowledge {
  Beginner = 'BEGINNER',
  Expert = 'EXPERT',
  Intermediate = 'INTERMEDIATE'
}

export type Form = {
  __typename?: 'Form';
  _id: Scalars['ID']['output'];
  form: Scalars['ArbitraryObject']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type FormManagement = {
  __typename?: 'FormManagement';
  form?: Maybe<Form>;
  formByName?: Maybe<Form>;
  forms: Array<Form>;
};


export type FormManagementFormArgs = {
  id: Scalars['ID']['input'];
};


export type FormManagementFormByNameArgs = {
  name: Scalars['String']['input'];
};


export type FormManagementFormsArgs = {
  names?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum Frequency {
  Daily = 'DAILY',
  Hourly = 'HOURLY',
  Soon = 'SOON',
  Weekly = 'WEEKLY'
}

export type Fund = {
  __typename?: 'Fund';
  _id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  underlying?: Maybe<Underlying>;
};

export type GenerateByWorkflowAndTypesInput = {
  documentTypes: Array<Scalars['String']['input']>;
  workflowId: Scalars['ID']['input'];
};

export type GenerateDocumentFooterInput = {
  pageNumber?: InputMaybe<Scalars['Boolean']['input']>;
  pageNumberX?: InputMaybe<Scalars['Float']['input']>;
  pageNumberY?: InputMaybe<Scalars['Float']['input']>;
};

export type GenerateDocumentHeaderInput = {
  logo?: InputMaybe<Scalars['String']['input']>;
  logoX?: InputMaybe<Scalars['Float']['input']>;
  logoY?: InputMaybe<Scalars['Float']['input']>;
};

export type GenerateDocumentInput = {
  footer?: InputMaybe<GenerateDocumentFooterInput>;
  header?: InputMaybe<GenerateDocumentHeaderInput>;
  name: Scalars['String']['input'];
  sections: Array<GenerateDocumentSectionInput>;
  source?: InputMaybe<DocumentSource>;
  type: Scalars['String']['input'];
};

export type GenerateDocumentSectionInput = {
  payload: Scalars['JSON']['input'];
  type: Scalars['String']['input'];
};

export type GeneratedDocument = {
  __typename?: 'GeneratedDocument';
  document?: Maybe<Document>;
  generated: Scalars['Boolean']['output'];
  signRequired?: Maybe<Scalars['Int']['output']>;
  signed?: Maybe<Scalars['Int']['output']>;
  type: Scalars['String']['output'];
  validRequired: Scalars['Int']['output'];
  validated: Scalars['Int']['output'];
  validations: Array<SubscriptionDocumentValidation>;
};

export type GeneratingDocumentSummary = {
  __typename?: 'GeneratingDocumentSummary';
  generated: Scalars['Boolean']['output'];
  generatedDocuments: Array<GeneratedDocument>;
  signRequired?: Maybe<Scalars['Int']['output']>;
  signed?: Maybe<Scalars['Int']['output']>;
  validRequired: Scalars['Int']['output'];
  validated: Scalars['Int']['output'];
};

export type GenericEvent = {
  __typename?: 'GenericEvent';
  payload?: Maybe<Scalars['ArbitraryObject']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type GenericSubscription = {
  __typename?: 'GenericSubscription';
  event?: Maybe<GenericEvent>;
  query?: Maybe<Query>;
};

export type GlobalKpi = {
  __typename?: 'GlobalKpi';
  netCollection: Scalars['Float']['output'];
  numberOfInvestors: Scalars['Int']['output'];
  numberOfPartners: Scalars['Int']['output'];
  totalAmount: Scalars['Float']['output'];
};

export type HiddenField = {
  __typename?: 'HiddenField';
  key?: Maybe<Scalars['String']['output']>;
  path?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['ID']['output']>;
};

export type I18NLabel = {
  __typename?: 'I18NLabel';
  lang: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export enum IdDocumentType {
  FrenchIdCard = 'FRENCH_ID_CARD',
  FrenchIdPassport = 'FRENCH_ID_PASSPORT',
  ResidencePermit = 'RESIDENCE_PERMIT'
}

export type IdentityValidation = {
  __typename?: 'IdentityValidation';
  failureAudit?: Maybe<IdentityValidationFailureAudit>;
  status?: Maybe<IdentityValidationStatus>;
  user: ExtendedUser;
};

export type IdentityValidationFailureAudit = {
  __typename?: 'IdentityValidationFailureAudit';
  failingZones: Array<IdentityValidationFailureZone>;
  mainReasonCode?: Maybe<Scalars['String']['output']>;
  reasonMessage?: Maybe<Scalars['String']['output']>;
};

export type IdentityValidationFailureZone = {
  __typename?: 'IdentityValidationFailureZone';
  expected: Scalars['String']['output'];
  field: Scalars['String']['output'];
  found: Scalars['String']['output'];
  valid: Scalars['Boolean']['output'];
};

export enum IdentityValidationStatus {
  Invalid = 'INVALID',
  Pending = 'PENDING',
  Valid = 'VALID'
}

export type Index = {
  __typename?: 'Index';
  _id: Scalars['ID']['output'];
  _internalClientId?: Maybe<Scalars['String']['output']>;
  clientId?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  perfAtDate?: Maybe<Scalars['Float']['output']>;
  perfByPeriod: Array<PerfValue>;
};

export type Indice = {
  __typename?: 'Indice';
  _id: Scalars['ID']['output'];
  changePercent?: Maybe<Scalars['Float']['output']>;
  symbol?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type InfoMessage = {
  __typename?: 'InfoMessage';
  message: Scalars['String']['output'];
  type: InfoMessageType;
};

export enum InfoMessageType {
  Error = 'ERROR',
  Warning = 'WARNING'
}

export type InpiUbo = {
  __typename?: 'InpiUbo';
  birthDate?: Maybe<Scalars['Int']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
};

export type Insight = Attachable & {
  __typename?: 'Insight';
  _id: Scalars['ID']['output'];
  creationDate?: Maybe<Scalars['Int']['output']>;
  investor?: Maybe<Investor>;
  payload?: Maybe<InsightPayload>;
  qualified?: Maybe<Scalars['Boolean']['output']>;
  qualifiedDate?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<InsightType>;
};

export type InsightPayload = {
  date?: Maybe<Scalars['Int']['output']>;
};

export enum InsightType {
  Behaviour = 'BEHAVIOUR',
  Lead = 'LEAD',
  Opinion = 'OPINION'
}

export type Instrument = {
  _id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type Investor = Attachable & ScreeningSubject & UserEntity & {
  __typename?: 'Investor';
  _id: Scalars['ID']['output'];
  _internalClientID?: Maybe<Scalars['String']['output']>;
  active: Scalars['Boolean']['output'];
  addonCharacteristics?: Maybe<Scalars['AddonJson']['output']>;
  advisor: ExtendedUser;
  advisorEntity: UserEntity;
  afId: Scalars['String']['output'];
  aum: AssetValue;
  company?: Maybe<Company>;
  connections: Array<ConnectionInformations>;
  contact?: Maybe<ExtendedUser>;
  contacts: Array<ExtendedUser>;
  contactsByType: Array<ExtendedUser>;
  content?: Maybe<ContentStatus>;
  contents: Array<ContentStatus>;
  createdDate?: Maybe<Scalars['Long']['output']>;
  document?: Maybe<Document>;
  documentRequest?: Maybe<DocumentRequest>;
  documentRequests: Array<DocumentRequest>;
  documents: Array<Document>;
  fileInputContacts: Array<ExtendedUser>;
  financial: Financial;
  hasExistingSubscriptions: Scalars['Boolean']['output'];
  inpiUbos: Array<InpiUbo>;
  investorGroups: Array<InvestorGroup>;
  invitationUrl?: Maybe<Scalars['String']['output']>;
  isDistributor: Scalars['Boolean']['output'];
  kycExpirationDate: Scalars['Int']['output'];
  lastKycDate?: Maybe<Scalars['Int']['output']>;
  leads: Array<Lead>;
  metrics: Array<InvestorMetric>;
  mlTfRiskRefreshNeeded?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  opinions: Array<Opinion>;
  ownerContact?: Maybe<ExtendedUser>;
  preSubscription?: Maybe<ProductPreSubscription>;
  preSubscriptions: Array<ProductPreSubscription>;
  primaryContact?: Maybe<ExtendedUser>;
  reportingContacts: Array<ExtendedUser>;
  signatoryContacts: Array<ExtendedUser>;
  subscribedProducts: Array<InvestorSubscribedProduct>;
  subscription?: Maybe<ProductSubscription>;
  subscriptions: Array<ProductSubscription>;
  tiering?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Typology>;
  userRemoveErrors: Array<UserError>;
};


export type InvestorConnectionsArgs = {
  options?: InputMaybe<Options>;
};


export type InvestorContactArgs = {
  userId: Scalars['ID']['input'];
};


export type InvestorContactsArgs = {
  contactType?: InputMaybe<Scalars['ClientCodeTypology']['input']>;
  signatory?: InputMaybe<Scalars['Boolean']['input']>;
};


export type InvestorContactsByTypeArgs = {
  contactType?: InputMaybe<Scalars['ClientCodeTypology']['input']>;
};


export type InvestorContentArgs = {
  id: Scalars['ID']['input'];
};


export type InvestorContentsArgs = {
  options?: InputMaybe<Options>;
};


export type InvestorDocumentArgs = {
  id: Scalars['ID']['input'];
};


export type InvestorDocumentRequestArgs = {
  id: Scalars['ID']['input'];
};


export type InvestorDocumentRequestsArgs = {
  options?: InputMaybe<Options>;
  status?: InputMaybe<DocumentRequestStatus>;
};


export type InvestorInpiUbosArgs = {
  companyName?: InputMaybe<Scalars['String']['input']>;
  siren?: InputMaybe<Scalars['String']['input']>;
};


export type InvestorInvitationUrlArgs = {
  userId: Scalars['ID']['input'];
};


export type InvestorMetricsArgs = {
  labels: Array<Scalars['ID']['input']>;
};


export type InvestorPreSubscriptionArgs = {
  id: Scalars['ID']['input'];
};


export type InvestorPreSubscriptionsArgs = {
  productId?: InputMaybe<Scalars['ID']['input']>;
};


export type InvestorSubscribedProductsArgs = {
  at?: InputMaybe<Scalars['Int']['input']>;
};


export type InvestorSubscriptionArgs = {
  id: Scalars['ID']['input'];
};


export type InvestorSubscriptionsArgs = {
  productId?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<Array<SubscriptionStatus>>;
};

export type InvestorGroup = {
  __typename?: 'InvestorGroup';
  _id: Scalars['ID']['output'];
  _internalClientID?: Maybe<Scalars['String']['output']>;
  composition: Array<Investor>;
  name: Scalars['String']['output'];
};

export type InvestorMetric = {
  __typename?: 'InvestorMetric';
  adjustedHolding?: Maybe<Scalars['Float']['output']>;
  adjustedWeight?: Maybe<Scalars['Float']['output']>;
  boosting?: Maybe<Scalars['Float']['output']>;
  holdings?: Maybe<Scalars['Float']['output']>;
  investor?: Maybe<Investor>;
  label?: Maybe<Label>;
  nuance?: Maybe<Scalars['String']['output']>;
  opinion?: Maybe<Scalars['Int']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
};

export type InvestorMutation = {
  __typename?: 'InvestorMutation';
  acknowledgeComplianceChange: Investor;
  addContact: Investor;
  addDocument?: Maybe<Investor>;
  addNewContact: Investor;
  checkCanAddContact?: Maybe<ExtendedUser>;
  monitorComplianceSearch: ComplyAdvantageScreening;
  removeContact: Investor;
  removeDocument?: Maybe<Investor>;
  update: Investor;
  updateAsUniquePrimaryContact?: Maybe<Investor>;
  updateContact: Investor;
  updateOpinions?: Maybe<Scalars['Boolean']['output']>;
};


export type InvestorMutationAddContactArgs = {
  input: AddContactInput;
};


export type InvestorMutationAddDocumentArgs = {
  input: DocumentInput;
};


export type InvestorMutationAddNewContactArgs = {
  userInput: AddNewInvestorContactInput;
};


export type InvestorMutationCheckCanAddContactArgs = {
  email: Scalars['String']['input'];
};


export type InvestorMutationMonitorComplianceSearchArgs = {
  monitor: Scalars['Boolean']['input'];
};


export type InvestorMutationRemoveContactArgs = {
  userId: Scalars['ID']['input'];
};


export type InvestorMutationRemoveDocumentArgs = {
  docId: Scalars['ID']['input'];
};


export type InvestorMutationUpdateArgs = {
  input: UpdateInvestorInput;
};


export type InvestorMutationUpdateAsUniquePrimaryContactArgs = {
  input: UpdateAsUniquePrimaryContactInput;
};


export type InvestorMutationUpdateContactArgs = {
  userId: Scalars['ID']['input'];
  userInput: UpdateUserInput;
};


export type InvestorMutationUpdateOpinionsArgs = {
  opinions: Array<OpinionInput>;
};

export type InvestorProspect = Attachable & UserEntity & {
  __typename?: 'InvestorProspect';
  _id: Scalars['ID']['output'];
  _internalClientID?: Maybe<Scalars['String']['output']>;
  active: Scalars['Boolean']['output'];
  addonCharacteristics?: Maybe<Scalars['AddonJson']['output']>;
  company?: Maybe<Company>;
  contacts: Array<ExtendedUser>;
  createdDate?: Maybe<Scalars['Int']['output']>;
  documents: Array<Document>;
  estimatedWealth?: Maybe<Scalars['Float']['output']>;
  investor?: Maybe<Investor>;
  mandateDocument?: Maybe<Document>;
  name: Scalars['String']['output'];
  onboarding: ProspectOnboarding;
  opportunity?: Maybe<BusinessOpportunity>;
  primaryContact?: Maybe<ExtendedUser>;
  riskProfile?: Maybe<Typology>;
  type: Typology;
};


export type InvestorProspectContactsArgs = {
  signatory?: InputMaybe<Scalars['Boolean']['input']>;
};


export type InvestorProspectDocumentsArgs = {
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type InvestorSubscribedProduct = {
  __typename?: 'InvestorSubscribedProduct';
  _id: Scalars['ID']['output'];
  composition_gross_value?: Maybe<Scalars['Float']['output']>;
  composition_investedAmount?: Maybe<Scalars['Float']['output']>;
  composition_investedDate?: Maybe<Scalars['Int']['output']>;
  composition_quantity?: Maybe<Scalars['Float']['output']>;
  composition_value?: Maybe<Scalars['Float']['output']>;
  date?: Maybe<Scalars['Int']['output']>;
  investor?: Maybe<Investor>;
  transaction_totalDistribution?: Maybe<Scalars['Float']['output']>;
  underlying_cotationCurrency?: Maybe<Scalars['String']['output']>;
  underlying_isin?: Maybe<Scalars['String']['output']>;
  underlying_name?: Maybe<Scalars['String']['output']>;
  underlying_nav?: Maybe<Scalars['Float']['output']>;
  underlying_nav_date?: Maybe<Scalars['Int']['output']>;
  underlying_nominal?: Maybe<Scalars['Float']['output']>;
  underlying_type?: Maybe<Scalars['String']['output']>;
};

export enum InvestorType {
  LegalPerson = 'LEGAL_PERSON',
  NaturalPerson = 'NATURAL_PERSON',
  Other = 'OTHER'
}

export type Invitation = {
  __typename?: 'Invitation';
  id: Scalars['ID']['output'];
  invitationEmail: Scalars['String']['output'];
  invitationSendDate: Scalars['Int']['output'];
  roles: Array<UserRoles>;
  user?: Maybe<BaseUser>;
  userEntity?: Maybe<UserEntity>;
};

export type Kpi = {
  __typename?: 'Kpi';
  positionKpi: PositionKpi;
  subscriptionFileKpi: SubscriptionFileKpi;
};

export type KycMeeting = Attachable & {
  __typename?: 'KycMeeting';
  _id: Scalars['ID']['output'];
  allSignatoriesStatus: KycMeetingSignatoryStatus;
  appointment: Appointment;
  note?: Maybe<Scalars['String']['output']>;
  prospect: InvestorProspect;
  signatories: Array<KycMeetingSignatory>;
  status: KycMeetingStatus;
};

export type KycMeetingSignatory = {
  __typename?: 'KycMeetingSignatory';
  status: KycMeetingSignatoryStatus;
  user: ExtendedUser;
};

export enum KycMeetingSignatoryStatus {
  Checked = 'CHECKED',
  Unchecked = 'UNCHECKED'
}

export enum KycMeetingStatus {
  InProgress = 'IN_PROGRESS',
  Planned = 'PLANNED',
  Validated = 'VALIDATED'
}

export type Label = {
  __typename?: 'Label';
  _id: Scalars['ID']['output'];
  hot?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  terms?: Maybe<Array<Scalars['String']['output']>>;
};

export type LabelGroup = {
  _id: Scalars['ID']['output'];
  labels: Array<LabelGroupLabel>;
  name?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
};

export type LabelGroupLabel = {
  __typename?: 'LabelGroupLabel';
  label?: Maybe<Label>;
  order?: Maybe<Scalars['Int']['output']>;
};

export type LabelMetrics = {
  __typename?: 'LabelMetrics';
  contentTaggedLastMonth?: Maybe<Scalars['Int']['output']>;
  contentTaggedLastWeek?: Maybe<Scalars['Int']['output']>;
  hot?: Maybe<Scalars['Boolean']['output']>;
  opinionsTagged?: Maybe<Scalars['Int']['output']>;
};

export type LabelResult = SearchResult & {
  __typename?: 'LabelResult';
  _id: Scalars['ID']['output'];
  index?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['String']['output']>;
  source?: Maybe<LabelSource>;
};

export type LabelSource = {
  __typename?: 'LabelSource';
  name?: Maybe<Scalars['String']['output']>;
};

export type Lead = InsightPayload & {
  __typename?: 'Lead';
  amount?: Maybe<Scalars['Float']['output']>;
  date?: Maybe<Scalars['Int']['output']>;
  sources: Array<Source>;
  summary?: Maybe<Scalars['String']['output']>;
};

export type ManagementCompany = {
  __typename?: 'ManagementCompany';
  internal?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type MatchingDigitalIdentity = {
  __typename?: 'MatchingDigitalIdentity';
  email?: Maybe<Scalars['String']['output']>;
  firstname: Scalars['String']['output'];
  lastname: Scalars['String']['output'];
  mobile?: Maybe<Scalars['String']['output']>;
};

export type MeetingReport = {
  __typename?: 'MeetingReport';
  date?: Maybe<Scalars['Int']['output']>;
  text: Scalars['String']['output'];
};

export type MeetingReportInput = {
  date?: InputMaybe<Scalars['Int']['input']>;
  text: Scalars['String']['input'];
};

export type Message = {
  __typename?: 'Message';
  _id: Scalars['ID']['output'];
  attachments?: Maybe<Array<Attachable>>;
  content?: Maybe<Scalars['String']['output']>;
  datetime?: Maybe<Scalars['Int']['output']>;
  messageId?: Maybe<Scalars['String']['output']>;
  read?: Maybe<Scalars['Boolean']['output']>;
  readDateTime?: Maybe<Scalars['Int']['output']>;
  received?: Maybe<Scalars['Boolean']['output']>;
  receivedDateTime?: Maybe<Scalars['Int']['output']>;
  sender?: Maybe<BaseUser>;
  seq?: Maybe<Scalars['Int']['output']>;
  thread?: Maybe<Scalars['ID']['output']>;
};

export type MessageAttachmentInput = {
  id: Scalars['ID']['input'];
  typeName: Scalars['String']['input'];
};

export type MessageRequest = {
  __typename?: 'MessageRequest';
  date?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type MessageResult = SearchResult & {
  __typename?: 'MessageResult';
  _id: Scalars['ID']['output'];
  index?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['String']['output']>;
  source?: Maybe<MessageSource>;
};

export type MessageSource = {
  __typename?: 'MessageSource';
  content?: Maybe<Scalars['String']['output']>;
  thread?: Maybe<Thread>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addDeviceToken: Array<DeviceToken>;
  alert?: Maybe<AlertMutation>;
  appointment?: Maybe<AppointmentMutation>;
  content?: Maybe<ContentMutation>;
  contentOpenDiscussion?: Maybe<Scalars['Boolean']['output']>;
  dashboardConfiguration?: Maybe<Scalars['ArbitraryObject']['output']>;
  deleteAllNotification?: Maybe<Scalars['Boolean']['output']>;
  documentRequest?: Maybe<DocumentRequestMutation>;
  investor?: Maybe<InvestorMutation>;
  notification?: Maybe<NotificationMutation>;
  onboardingProcess?: Maybe<OnboardingProcessMutation>;
  productGuide: ProductGuideMutation;
  productPreSubscription?: Maybe<ProductPreSubscriptionMutation>;
  productSubscription?: Maybe<ProductSubscriptionMutation>;
  requestMFA: Scalars['Boolean']['output'];
  thread?: Maybe<ThreadMutation>;
  updateNotificationConfigs?: Maybe<ConnectedUser>;
  updateProfilePic?: Maybe<ConnectedUser>;
  user?: Maybe<UserMutation>;
  workflow: WorkflowMutation;
};


export type MutationAddDeviceTokenArgs = {
  deviceToken?: InputMaybe<DeviceTokenInput>;
};


export type MutationAlertArgs = {
  id: Scalars['ID']['input'];
};


export type MutationAppointmentArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationContentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationContentOpenDiscussionArgs = {
  contentId: Scalars['ID']['input'];
};


export type MutationDashboardConfigurationArgs = {
  config?: InputMaybe<Scalars['ArbitraryObject']['input']>;
};


export type MutationInvestorArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationNotificationArgs = {
  id: Scalars['ID']['input'];
};


export type MutationProductPreSubscriptionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationProductSubscriptionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationRequestMfaArgs = {
  message: Scalars['String']['input'];
  messageDetail: Scalars['String']['input'];
};


export type MutationThreadArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationUpdateNotificationConfigsArgs = {
  notificationConfigs: Array<NotificationConfigInput>;
};


export type MutationUpdateProfilePicArgs = {
  profilePic: Scalars['String']['input'];
};


export type MutationUserArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationWorkflowArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type Nav = {
  __typename?: 'Nav';
  valuationNAV: Scalars['Float']['output'];
  valuationNAVDate: Scalars['Int']['output'];
};

export type NetworkLink = {
  __typename?: 'NetworkLink';
  _id: Scalars['String']['output'];
  from: NetworkLinkSupplier;
  to: NetworkLinkSupplier;
};

export type NetworkLinkSupplier = {
  __typename?: 'NetworkLinkSupplier';
  _id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  type: SupplierType;
};

export type NotSignedBlock = {
  __typename?: 'NotSignedBlock';
  qualifiedSigners: Array<ExtendedUser>;
  userEntityTypes?: Maybe<Array<UserEntityType>>;
  userProfiles: Array<WorkflowTaskAuditUserProfile>;
};

export type Notifiable = {
  _id: Scalars['ID']['output'];
  attachments: Array<Attachable>;
  datetime?: Maybe<Scalars['Int']['output']>;
  event?: Maybe<GenericEvent>;
  from?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  type?: Maybe<NotifiableType>;
};

export enum NotifiableType {
  Alert_10PPerfCompBreach = 'ALERT_10P_PERF_COMP_BREACH',
  AlertAllocationChange = 'ALERT_ALLOCATION_CHANGE',
  AlertAmlCftCommittee = 'ALERT_AML_CFT_COMMITTEE',
  AlertBehaviour = 'ALERT_BEHAVIOUR',
  AlertContent = 'ALERT_CONTENT',
  AlertCorporateAction = 'ALERT_CORPORATE_ACTION',
  AlertCouponPayment = 'ALERT_COUPON_PAYMENT',
  AlertDistribution = 'ALERT_DISTRIBUTION',
  AlertDistributionAgreementReceived = 'ALERT_DISTRIBUTION_AGREEMENT_RECEIVED',
  AlertDividendPayment = 'ALERT_DIVIDEND_PAYMENT',
  AlertDocumentsInternalSigning = 'ALERT_DOCUMENTS_INTERNAL_SIGNING',
  AlertDocumentsProspectSigning = 'ALERT_DOCUMENTS_PROSPECT_SIGNING',
  AlertDocumentsToBeValidated = 'ALERT_DOCUMENTS_TO_BE_VALIDATED',
  AlertEntityPerformanceDrop = 'ALERT_ENTITY_PERFORMANCE_DROP',
  AlertExposureBreach = 'ALERT_EXPOSURE_BREACH',
  AlertFees = 'ALERT_FEES',
  AlertIdExpirationDate = 'ALERT_ID_EXPIRATION_DATE',
  AlertInvestorAdvisorSubscriptionBaSigning = 'ALERT_INVESTOR_ADVISOR_SUBSCRIPTION_BA_SIGNING',
  AlertInvestorExpiredDoc = 'ALERT_INVESTOR_EXPIRED_DOC',
  AlertInvestorSubscriptionAdvisorToConformityValidation = 'ALERT_INVESTOR_SUBSCRIPTION_ADVISOR_TO_CONFORMITY_VALIDATION',
  AlertInvestorSubscriptionBaSigning = 'ALERT_INVESTOR_SUBSCRIPTION_BA_SIGNING',
  AlertInvestorSubscriptionCompleted = 'ALERT_INVESTOR_SUBSCRIPTION_COMPLETED',
  AlertInvestorSubscriptionConformityValidation = 'ALERT_INVESTOR_SUBSCRIPTION_CONFORMITY_VALIDATION',
  AlertInvestorSubscriptionCustodianRefusal = 'ALERT_INVESTOR_SUBSCRIPTION_CUSTODIAN_REFUSAL',
  AlertInvestorSubscriptionCustodianValidation = 'ALERT_INVESTOR_SUBSCRIPTION_CUSTODIAN_VALIDATION',
  AlertInvestorSubscriptionDistributorValidation = 'ALERT_INVESTOR_SUBSCRIPTION_DISTRIBUTOR_VALIDATION',
  AlertInvestorSubscriptionDocumentsAttachments = 'ALERT_INVESTOR_SUBSCRIPTION_DOCUMENTS_ATTACHMENTS',
  AlertInvestorSubscriptionPlatformRefusal = 'ALERT_INVESTOR_SUBSCRIPTION_PLATFORM_REFUSAL',
  AlertInvestorSubscriptionPlatformValidation = 'ALERT_INVESTOR_SUBSCRIPTION_PLATFORM_VALIDATION',
  AlertInvestorSubscriptionPmcConformityRefusal = 'ALERT_INVESTOR_SUBSCRIPTION_PMC_CONFORMITY_REFUSAL',
  AlertInvestorSubscriptionPmcRefusal = 'ALERT_INVESTOR_SUBSCRIPTION_PMC_REFUSAL',
  AlertKycExpiration = 'ALERT_KYC_EXPIRATION',
  AlertKycMeetingPlanification = 'ALERT_KYC_MEETING_PLANIFICATION',
  AlertKycRefreshNeeded = 'ALERT_KYC_REFRESH_NEEDED',
  AlertLackInteraction = 'ALERT_LACK_INTERACTION',
  AlertLiquidity = 'ALERT_LIQUIDITY',
  AlertMltfCheck = 'ALERT_MLTF_CHECK',
  AlertMltfRiskValidation = 'ALERT_MLTF_RISK_VALIDATION',
  AlertOnboardingFileValidation = 'ALERT_ONBOARDING_FILE_VALIDATION',
  AlertOnboardingMissingDoc = 'ALERT_ONBOARDING_MISSING_DOC',
  AlertOpinion = 'ALERT_OPINION',
  AlertOrderExecution = 'ALERT_ORDER_EXECUTION',
  AlertOther = 'ALERT_OTHER',
  AlertPerformanceBreach = 'ALERT_PERFORMANCE_BREACH',
  AlertPriceMovement = 'ALERT_PRICE_MOVEMENT',
  AlertProfileUpdate = 'ALERT_PROFILE_UPDATE',
  AlertRatingChange = 'ALERT_RATING_CHANGE',
  AlertRatingDowngrade = 'ALERT_RATING_DOWNGRADE',
  AlertRebalancing = 'ALERT_REBALANCING',
  AlertRedemption = 'ALERT_REDEMPTION',
  AlertRefusedDocuments = 'ALERT_REFUSED_DOCUMENTS',
  AlertReminderInvestorExpiredDoc = 'ALERT_REMINDER_INVESTOR_EXPIRED_DOC',
  AlertReminderSignatoryExpiredDoc = 'ALERT_REMINDER_SIGNATORY_EXPIRED_DOC',
  AlertRiskDeterioration = 'ALERT_RISK_DETERIORATION',
  AlertRiskProfileAnnualReview = 'ALERT_RISK_PROFILE_ANNUAL_REVIEW',
  AlertSignatoryExpiredDoc = 'ALERT_SIGNATORY_EXPIRED_DOC',
  AlertSignatoryLegalAge = 'ALERT_SIGNATORY_LEGAL_AGE',
  AlertSrriBreach = 'ALERT_SRRI_BREACH',
  AlertViewDowngrade = 'ALERT_VIEW_DOWNGRADE',
  Message = 'MESSAGE',
  Mfa = 'MFA',
  NotificationAppointmentRequest = 'NOTIFICATION_APPOINTMENT_REQUEST',
  NotificationInteraction = 'NOTIFICATION_INTERACTION',
  NotificationInvestmentProposal = 'NOTIFICATION_INVESTMENT_PROPOSAL',
  NotificationNewDocument = 'NOTIFICATION_NEW_DOCUMENT',
  NotificationNewDocumentRequest = 'NOTIFICATION_NEW_DOCUMENT_REQUEST',
  NotificationOther = 'NOTIFICATION_OTHER',
  NotificationPerformanceReview = 'NOTIFICATION_PERFORMANCE_REVIEW',
  NotificationPortfolioAlert = 'NOTIFICATION_PORTFOLIO_ALERT',
  NotificationTaskClosed = 'NOTIFICATION_TASK_CLOSED',
  NotificationTaskCreated = 'NOTIFICATION_TASK_CREATED',
  NotificationTaskUpdated = 'NOTIFICATION_TASK_UPDATED'
}

export type Notification = Notifiable & {
  __typename?: 'Notification';
  _id: Scalars['ID']['output'];
  attachments: Array<Attachable>;
  datetime?: Maybe<Scalars['Int']['output']>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  event?: Maybe<GenericEvent>;
  from?: Maybe<Scalars['String']['output']>;
  fromUser?: Maybe<BaseUser>;
  key?: Maybe<Scalars['String']['output']>;
  read?: Maybe<Scalars['Boolean']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  type?: Maybe<NotifiableType>;
};

export type NotificationConfig = {
  __typename?: 'NotificationConfig';
  enableEmail?: Maybe<Scalars['Boolean']['output']>;
  enablePushNotif?: Maybe<Scalars['Boolean']['output']>;
  frequency?: Maybe<Frequency>;
  type?: Maybe<NotifiableType>;
};

export type NotificationConfigInput = {
  enableEmail?: InputMaybe<Scalars['Boolean']['input']>;
  enablePushNotif?: InputMaybe<Scalars['Boolean']['input']>;
  frequency?: InputMaybe<Frequency>;
  type?: InputMaybe<NotifiableType>;
};

export type NotificationMutation = {
  __typename?: 'NotificationMutation';
  delete?: Maybe<Scalars['Boolean']['output']>;
  deleteAll?: Maybe<Scalars['Boolean']['output']>;
};

export type OnboardingDocument = {
  __typename?: 'OnboardingDocument';
  documentType: Typology;
  isRequired: Scalars['Boolean']['output'];
};

export type OnboardingFile = {
  __typename?: 'OnboardingFile';
  _id: Scalars['ID']['output'];
  appointment?: Maybe<Appointment>;
  documents: Array<Document>;
  emailSendDateTime?: Maybe<Scalars['Int']['output']>;
  formData?: Maybe<Scalars['ArbitraryObject']['output']>;
  index?: Maybe<Scalars['Int']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  prospect?: Maybe<InvestorProspect>;
  sentToComplianceDateTime?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<OnboardingFileStatus>;
  time?: Maybe<Scalars['Int']['output']>;
  universignTransactionId?: Maybe<Scalars['String']['output']>;
  universignTransactionUrl?: Maybe<Scalars['String']['output']>;
};

export enum OnboardingFileStatus {
  Initialised = 'INITIALISED',
  OngoingMeeting = 'ONGOING_MEETING',
  OngoingValidation = 'ONGOING_VALIDATION',
  PlannedMeeting = 'PLANNED_MEETING',
  SentToCompliance = 'SENT_TO_COMPLIANCE',
  SignedButNotSent = 'SIGNED_BUT_NOT_SENT',
  Untreated = 'UNTREATED',
  Validated = 'VALIDATED'
}

export type OnboardingProcessMutation = {
  __typename?: 'OnboardingProcessMutation';
  updateFinancialKnowledge?: Maybe<ConnectedUser>;
  updateNewsAlert?: Maybe<ConnectedUser>;
  updateNotificationConfigs?: Maybe<ConnectedUser>;
  updateOpinions?: Maybe<Scalars['Boolean']['output']>;
};


export type OnboardingProcessMutationUpdateFinancialKnowledgeArgs = {
  financialKnowledge: FinancialKnowledge;
};


export type OnboardingProcessMutationUpdateNewsAlertArgs = {
  newsAlert: Scalars['Boolean']['input'];
};


export type OnboardingProcessMutationUpdateNotificationConfigsArgs = {
  notificationConfigs: Array<NotificationConfigInput>;
};


export type OnboardingProcessMutationUpdateOpinionsArgs = {
  opinions: Array<OpinionInput>;
};

export type Opinion = InsightPayload & Tag & {
  __typename?: 'Opinion';
  date?: Maybe<Scalars['Int']['output']>;
  label?: Maybe<Label>;
  nuance?: Maybe<OpinionNuance>;
  source?: Maybe<OpinionSource>;
};

export type OpinionGroup = LabelGroup & {
  __typename?: 'OpinionGroup';
  _id: Scalars['ID']['output'];
  labels: Array<LabelGroupLabel>;
  name?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
};

export type OpinionInput = {
  id: Scalars['ID']['input'];
  nuance: OpinionNuance;
  source: OpinionSource;
};

export enum OpinionNuance {
  Against = 'AGAINST',
  Conviction = 'CONVICTION',
  Interest = 'INTEREST'
}

export enum OpinionSource {
  Advisor = 'ADVISOR',
  Investor = 'INVESTOR'
}

export type Options = {
  filters?: InputMaybe<Array<Filter>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Sort>;
  sorts?: InputMaybe<Array<Sort>>;
};

export enum Origin {
  End = 'END',
  Start = 'START'
}

export type Pep = {
  __typename?: 'PEP';
  isPEP?: Maybe<Scalars['Boolean']['output']>;
  reasonForPEP?: Maybe<Scalars['String']['output']>;
};

export type PepInput = {
  isPEP?: InputMaybe<Scalars['Boolean']['input']>;
  reasonForPEP?: InputMaybe<Scalars['String']['input']>;
};

export type PageTraffic = {
  __typename?: 'PageTraffic';
  page?: Maybe<Scalars['String']['output']>;
  visits?: Maybe<Scalars['Int']['output']>;
};

export type PerfValue = {
  __typename?: 'PerfValue';
  from?: Maybe<Scalars['Int']['output']>;
  period?: Maybe<Scalars['String']['output']>;
  to?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type Performance = {
  __typename?: 'Performance';
  _id: Scalars['ID']['output'];
  calculationDate?: Maybe<Scalars['Int']['output']>;
  currencyCode?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['Int']['output']>;
  instrument?: Maybe<Instrument>;
  netReturn?: Maybe<Scalars['Float']['output']>;
  periodCode?: Maybe<Period>;
};

export enum PerformanceFrequency {
  M1 = 'M1',
  W1 = 'W1'
}

export enum Period {
  M1 = 'M1',
  M3 = 'M3',
  M6 = 'M6',
  Mtd = 'MTD',
  Si = 'SI',
  Y1 = 'Y1',
  Y3 = 'Y3',
  Y5 = 'Y5',
  Ytd = 'YTD'
}

export type Permission = {
  __typename?: 'Permission';
  id: Scalars['String']['output'];
  platform: Scalars['String']['output'];
  roles: Array<Scalars['String']['output']>;
};

export type PositionKpi = {
  __typename?: 'PositionKpi';
  amountsPerMonth: Array<AmountPerMonth>;
  amountsPerUnderlying: Array<AmountPerUnderlying>;
  amountsPerUnderlyingType: Array<AmountPerUnderlyingType>;
  collectsPerMonth: Array<CollectPerMonth>;
  globalKpi: GlobalKpi;
};


export type PositionKpiAmountsPerUnderlyingArgs = {
  date?: InputMaybe<Scalars['Int']['input']>;
};


export type PositionKpiAmountsPerUnderlyingTypeArgs = {
  date?: InputMaybe<Scalars['Int']['input']>;
};

export type PreSubscriptionFileValidationAuditInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  status: SubscriptionFileValidationStatus;
};

export type PreSubscriptionInput = {
  _id: Scalars['ID']['input'];
  type: SubscriptionNomineeType;
};

export enum PreSubscriptionStatus {
  Closed = 'CLOSED',
  InProgress = 'IN_PROGRESS',
  Validated = 'VALIDATED'
}

export type PredictedContentGroup = {
  __typename?: 'PredictedContentGroup';
  _id: Scalars['ID']['output'];
  labels?: Maybe<Array<Label>>;
  name?: Maybe<Scalars['String']['output']>;
};

export type PrivateClientKpi = {
  __typename?: 'PrivateClientKpi';
  from: Scalars['Int']['output'];
  lostPrivateClients: Scalars['Int']['output'];
  newPrivateClients: Scalars['Int']['output'];
  numberPrivateClients: Scalars['Int']['output'];
  to: Scalars['Int']['output'];
  totalAssetPrivateClients: AssetValue;
  totalExistingPrivateClientsContributions: AssetValue;
  totalExistingPrivateClientsWithdrawals: AssetValue;
  totalLostPrivateClientsContributions: AssetValue;
  totalLostPrivateClientsWithdrawals: AssetValue;
  totalNewPrivateClientsContributions: AssetValue;
  totalNewPrivateClientsWithdrawals: AssetValue;
  totalTransactionsPrivateClients: AssetValue;
};

export type ProductDistributionChannel = {
  __typename?: 'ProductDistributionChannel';
  networkLinks: Array<NetworkLink>;
};

export type ProductGuide = {
  __typename?: 'ProductGuide';
  _id: Scalars['ID']['output'];
  guides: Array<Scalars['String']['output']>;
};

export type ProductGuideMutation = {
  __typename?: 'ProductGuideMutation';
  add: ProductGuide;
};


export type ProductGuideMutationAddArgs = {
  id: Scalars['String']['input'];
};

export type ProductPreSubscription = {
  _id: Scalars['ID']['output'];
  _internalClientID: Scalars['String']['output'];
  amount: Scalars['Float']['output'];
  lastRunningTaskDate: Scalars['Int']['output'];
};

export type ProductPreSubscriptionContact = {
  __typename?: 'ProductPreSubscriptionContact';
  _id: Scalars['ID']['output'];
  initialEmailSendDate?: Maybe<Scalars['Int']['output']>;
  invitationLink?: Maybe<Scalars['String']['output']>;
  lastEmailSendDate?: Maybe<Scalars['Int']['output']>;
  user: ExtendedUser;
};

export type ProductPreSubscriptionMutation = {
  __typename?: 'ProductPreSubscriptionMutation';
  addDistributorDocument: ExtendedProductPreSubscription;
  addValidationAudit: ExtendedProductPreSubscription;
  checkCanStartPreSubscription: Array<InfoMessage>;
  delete: Scalars['Boolean']['output'];
  removeContact: Investor;
  removeDistributorDocument: ExtendedProductPreSubscription;
  resendInvitationEmail: Scalars['Boolean']['output'];
  resendSubscriptionInvitationEmail: ExtendedProductPreSubscription;
  sendInvitationEmail: Scalars['Boolean']['output'];
  startPreSubscription: ExtendedProductPreSubscription;
  stopPreSubscription: ExtendedProductPreSubscription;
  update: ExtendedProductPreSubscription;
  updateForSubscriber: ExtendedProductPreSubscription;
};


export type ProductPreSubscriptionMutationAddDistributorDocumentArgs = {
  input: DocumentInput;
};


export type ProductPreSubscriptionMutationAddValidationAuditArgs = {
  input: PreSubscriptionFileValidationAuditInput;
};


export type ProductPreSubscriptionMutationCheckCanStartPreSubscriptionArgs = {
  input: CanStartPreSubscriptionInput;
};


export type ProductPreSubscriptionMutationRemoveContactArgs = {
  investorId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type ProductPreSubscriptionMutationRemoveDistributorDocumentArgs = {
  docId: Scalars['ID']['input'];
};


export type ProductPreSubscriptionMutationResendInvitationEmailArgs = {
  message?: InputMaybe<Scalars['String']['input']>;
  taskId: Scalars['ID']['input'];
};


export type ProductPreSubscriptionMutationResendSubscriptionInvitationEmailArgs = {
  userId: Scalars['ID']['input'];
};


export type ProductPreSubscriptionMutationSendInvitationEmailArgs = {
  message?: InputMaybe<Scalars['String']['input']>;
};


export type ProductPreSubscriptionMutationStartPreSubscriptionArgs = {
  input: StartPreSubscriptionInput;
};


export type ProductPreSubscriptionMutationStopPreSubscriptionArgs = {
  input: StopWorkflowInput;
};


export type ProductPreSubscriptionMutationUpdateArgs = {
  input: UpdateProductPreSubscriptionInput;
};


export type ProductPreSubscriptionMutationUpdateForSubscriberArgs = {
  input: UpdateSubscriberProductPreSubscriptionInput;
  subscriberId: Scalars['ID']['input'];
};

export type ProductPreSubscriptionsFilterInput = {
  actionRequired?: InputMaybe<Scalars['Boolean']['input']>;
  dateRange?: InputMaybe<DateRangeInput>;
  fundIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  integratedIntoSubscription?: InputMaybe<Scalars['Boolean']['input']>;
  investorName?: InputMaybe<Scalars['String']['input']>;
  investorType?: InputMaybe<Scalars['ClientCodeTypology']['input']>;
  organizationUnits?: InputMaybe<Array<Scalars['String']['input']>>;
  productIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  status?: InputMaybe<Array<PreSubscriptionStatus>>;
  workflowTasksGroupNames?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ProductSubscription = Attachable & {
  __typename?: 'ProductSubscription';
  _externalClientID?: Maybe<Scalars['String']['output']>;
  _id: Scalars['ID']['output'];
  _internalClientID: Scalars['String']['output'];
  acaCommitmentRatio: Scalars['Float']['output'];
  actualOwner?: Maybe<SubscriptionOwnership>;
  addonCharacteristics?: Maybe<Scalars['AddonJson']['output']>;
  advisor: UserEntity;
  amount: Scalars['Float']['output'];
  coSubscriptionDocumentChecklist: Array<OnboardingDocument>;
  /**  contacts involved in the subscription file process (editing and signing users) */
  contacts: Array<ProductSubscriptionContact>;
  date?: Maybe<Scalars['Int']['output']>;
  distributionChannel?: Maybe<DistributionChannel>;
  distributorDocuments: Array<Document>;
  documentChecklist: Array<OnboardingDocument>;
  fees?: Maybe<SubscriptionFees>;
  handWrittenAmount?: Maybe<Scalars['String']['output']>;
  handWrittenNote?: Maybe<Scalars['String']['output']>;
  invitationNote?: Maybe<Scalars['String']['output']>;
  lastRunningTaskDate: Scalars['Int']['output'];
  nominee: Scalars['Boolean']['output'];
  numberOfShares: Scalars['Float']['output'];
  pooledPreSubscriptions: Array<ProductSubscriptionPreSubscription>;
  preSubscriptions: Array<ProductSubscriptionPreSubscription>;
  product: SubscribableProduct;
  segregatedPreSubscriptions: Array<ProductSubscriptionPreSubscription>;
  status: SubscriptionStatus;
  subscriberAddonCharacteristics?: Maybe<Scalars['AddonJson']['output']>;
  subscribers: Array<ProductSubscriptionSubscriber>;
  subscriptionDocuments: Array<SubscriptionDocument>;
  support?: Maybe<SubscriptionSupport>;
  workflow: Workflow;
};


export type ProductSubscriptionSubscriberAddonCharacteristicsArgs = {
  subscriberId: Scalars['ID']['input'];
};

export type ProductSubscriptionContact = {
  __typename?: 'ProductSubscriptionContact';
  _id: Scalars['ID']['output'];
  initialEmailSendDate?: Maybe<Scalars['Int']['output']>;
  invitationLink?: Maybe<Scalars['String']['output']>;
  lastEmailSendDate?: Maybe<Scalars['Int']['output']>;
  user: ExtendedUser;
};

export type ProductSubscriptionMutation = {
  __typename?: 'ProductSubscriptionMutation';
  addDistributorDocument: ProductSubscription;
  addValidationAudit: ProductSubscription;
  checkCanStartSubscription: Array<InfoMessage>;
  delete: Scalars['Boolean']['output'];
  removeContact: Investor;
  removeDistributorDocument: ProductSubscription;
  resendInvitationEmail: Scalars['Boolean']['output'];
  resendSubscriptionInvitationEmail: ProductSubscription;
  sendInvitationEmail: Scalars['Boolean']['output'];
  startSubscription: ProductSubscription;
  stopSubscription: ProductSubscription;
  update: ProductSubscription;
  updateForPreSubscriptions: ProductSubscription;
  updateForSubscriber: ProductSubscription;
};


export type ProductSubscriptionMutationAddDistributorDocumentArgs = {
  input: DocumentInput;
};


export type ProductSubscriptionMutationAddValidationAuditArgs = {
  input: SubscriptionFileValidationAuditInput;
};


export type ProductSubscriptionMutationCheckCanStartSubscriptionArgs = {
  input: CanStartSubscriptionInput;
};


export type ProductSubscriptionMutationRemoveContactArgs = {
  investorId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type ProductSubscriptionMutationRemoveDistributorDocumentArgs = {
  docId: Scalars['ID']['input'];
};


export type ProductSubscriptionMutationResendInvitationEmailArgs = {
  message?: InputMaybe<Scalars['String']['input']>;
  taskId: Scalars['ID']['input'];
};


export type ProductSubscriptionMutationResendSubscriptionInvitationEmailArgs = {
  userId: Scalars['ID']['input'];
};


export type ProductSubscriptionMutationSendInvitationEmailArgs = {
  message?: InputMaybe<Scalars['String']['input']>;
};


export type ProductSubscriptionMutationStartSubscriptionArgs = {
  input: StartSubscriptionInput;
};


export type ProductSubscriptionMutationStopSubscriptionArgs = {
  input: StopWorkflowInput;
};


export type ProductSubscriptionMutationUpdateArgs = {
  input: UpdateProductSubscriptionInput;
};


export type ProductSubscriptionMutationUpdateForPreSubscriptionsArgs = {
  input: Array<UpdatePreSubscriptionOfProductSubscriptionInput>;
};


export type ProductSubscriptionMutationUpdateForSubscriberArgs = {
  input: UpdateSubscriberProductSubscriptionInput;
  subscriberId: Scalars['ID']['input'];
};

export type ProductSubscriptionPreSubscription = {
  __typename?: 'ProductSubscriptionPreSubscription';
  addonCharacteristics?: Maybe<Scalars['AddonJson']['output']>;
  productPreSubscription: ProductPreSubscription;
  subscriberInternalClientID: Scalars['String']['output'];
  type: SubscriptionNomineeType;
};

export type ProductSubscriptionSubscriber = {
  __typename?: 'ProductSubscriptionSubscriber';
  addonCharacteristics?: Maybe<Scalars['AddonJson']['output']>;
  investor: Investor;
  invitationUrl?: Maybe<Scalars['String']['output']>;
};

export type ProductSubscriptionsFilterInput = {
  actionRequired?: InputMaybe<Scalars['Boolean']['input']>;
  advisorIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  dateRange?: InputMaybe<DateRangeInput>;
  distributorIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  fundIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  investorName?: InputMaybe<Scalars['String']['input']>;
  investorType?: InputMaybe<Scalars['ClientCodeTypology']['input']>;
  organizationUnits?: InputMaybe<Array<Scalars['String']['input']>>;
  platformId?: InputMaybe<Scalars['ID']['input']>;
  productIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  status?: InputMaybe<Array<SubscriptionStatus>>;
  workflowTasksGroupNames?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ProfileRight = {
  __typename?: 'ProfileRight';
  id: Scalars['String']['output'];
  platform: Scalars['String']['output'];
  roles: Array<Scalars['String']['output']>;
};

export type ProspectOnboarding = {
  __typename?: 'ProspectOnboarding';
  documentChecklist: Array<OnboardingDocument>;
  workflows: Array<Workflow>;
};

export type Quarter = {
  __typename?: 'Quarter';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  assetManagerLogo: Scalars['String']['output'];
  clientConfig?: Maybe<ClientConfig>;
  content?: Maybe<Content>;
  contentGroups?: Maybe<Array<ContentGroup>>;
  /**  Labels of contents matching the specified kind */
  contentLabels?: Maybe<Array<Label>>;
  contents?: Maybe<Array<Content>>;
  currencyRate?: Maybe<Scalars['Float']['output']>;
  dashboardConfiguration?: Maybe<Scalars['ArbitraryObject']['output']>;
  fund?: Maybe<Fund>;
  funds?: Maybe<Array<Fund>>;
  hiddenFields?: Maybe<Array<HiddenField>>;
  indexes: Array<Index>;
  labels?: Maybe<Array<Label>>;
  me: ConnectedUser;
  opinionGroups?: Maybe<Array<OpinionGroup>>;
  opinions?: Maybe<Array<Label>>;
  search?: Maybe<Array<SearchResult>>;
  translation?: Maybe<Translation>;
  translations?: Maybe<Array<Translation>>;
  typology: Scalars['JSON']['output'];
  underlying?: Maybe<Underlying>;
  user: ConnectedUser;
};


export type QueryContentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryContentGroupsArgs = {
  kind: Scalars['String']['input'];
};


export type QueryContentLabelsArgs = {
  kind: Scalars['String']['input'];
};


export type QueryContentsArgs = {
  hot?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<Scalars['String']['input']>;
  labels?: InputMaybe<EntitySelector>;
  options?: InputMaybe<Options>;
  since?: InputMaybe<Scalars['Int']['input']>;
  video?: InputMaybe<Video>;
};


export type QueryCurrencyRateArgs = {
  at?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFundArgs = {
  id: Scalars['ID']['input'];
};


export type QueryIndexesArgs = {
  at?: InputMaybe<Scalars['Int']['input']>;
  indexCodes: Array<Scalars['String']['input']>;
  periods?: InputMaybe<Array<Period>>;
};


export type QueryLabelsArgs = {
  hot?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySearchArgs = {
  index?: InputMaybe<SearchIndex>;
  word: Scalars['String']['input'];
};


export type QueryTranslationArgs = {
  id: Scalars['String']['input'];
};


export type QueryTranslationsArgs = {
  ids: Array<Scalars['String']['input']>;
};


export type QueryTypologyArgs = {
  lang: Scalars['String']['input'];
};


export type QueryUnderlyingArgs = {
  id: Scalars['ID']['input'];
};

export type RangeDate = {
  origin: Origin;
  value: Scalars['Int']['input'];
};

export type Reference = {
  __typename?: 'Reference';
  id: Scalars['String']['output'];
  ref: Scalars['String']['output'];
};

export type RefuseTaskInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  extra?: InputMaybe<Scalars['JSON']['input']>;
  parameters?: InputMaybe<Scalars['JSON']['input']>;
  taskId: Scalars['ID']['input'];
};

export type RemediationFile = {
  __typename?: 'RemediationFile';
  _id: Scalars['ID']['output'];
  date?: Maybe<Scalars['Int']['output']>;
  status: RemediationStatus;
  subject: ScreeningSubject;
  workflow: Workflow;
};

export type RemediationFileFilterInput = {
  status?: InputMaybe<Array<RemediationStatus>>;
  subjectName?: InputMaybe<Scalars['String']['input']>;
  subjectType?: InputMaybe<RemediationSubjectType>;
};

export enum RemediationStatus {
  Closed = 'CLOSED',
  InProgress = 'IN_PROGRESS',
  Validated = 'VALIDATED'
}

export enum RemediationSubjectType {
  Entity = 'Entity',
  Individual = 'Individual'
}

export type RoleBasedAlert = {
  __typename?: 'RoleBasedAlert';
  alert: Scalars['String']['output'];
  role?: Maybe<UserRoles>;
};

export type Route = {
  __typename?: 'Route';
  path: Scalars['String']['output'];
  permission: Permission;
};

export type Screening = AbstractScreening & {
  __typename?: 'Screening';
  _id: Scalars['String']['output'];
  comment?: Maybe<Scalars['String']['output']>;
  date: Scalars['Int']['output'];
  documents: Array<Document>;
  status?: Maybe<Typology>;
};

export type ScreeningInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  documents: Array<DocumentInput>;
  status: Scalars['ClientCodeTypology']['input'];
};

export type ScreeningSubject = {
  _id: Scalars['ID']['output'];
};

export enum SearchIndex {
  Content = 'content',
  Messages = 'messages'
}

export type SearchResult = {
  _id: Scalars['ID']['output'];
  index?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['String']['output']>;
};

export type ShareClass = {
  __typename?: 'ShareClass';
  currency: Scalars['String']['output'];
  fund: Fund;
  internalRateOfReturnGoal?: Maybe<Scalars['Float']['output']>;
  isin: Scalars['String']['output'];
  legalForm?: Maybe<Scalars['String']['output']>;
  minimumOfSubscription?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  recommendedInvestmentPeriod?: Maybe<Scalars['Int']['output']>;
};

export type SignedBlock = {
  __typename?: 'SignedBlock';
  date: Scalars['Int']['output'];
  proofDocument?: Maybe<Document>;
  status: UniversignSignerInfoStatus;
  user?: Maybe<ExtendedUser>;
  userEntityTypes?: Maybe<Array<UserEntityType>>;
  userProfiles: Array<WorkflowTaskAuditUserProfile>;
};

export type SignerBlock = {
  __typename?: 'SignerBlock';
  signature: UniversignSignature;
  status: UniversignSignerInfoStatus;
  user?: Maybe<ExtendedUser>;
  userEntityTypes?: Maybe<Array<UserEntityType>>;
  userProfiles: Array<WorkflowTaskAuditUserProfile>;
};

export type Signing = {
  __typename?: 'Signing';
  hasDigitalIdentityValidationSession: Scalars['Boolean']['output'];
};

export type SigningBlock = NotSignedBlock | SignedBlock | SignerBlock;

export type SigningMutation = {
  __typename?: 'SigningMutation';
  /**  create and update digital signing transaction for connected user or for user identified by 'userId' and for the list of documents identified by "ids" */
  updateTransaction: SigningTransaction;
};


export type SigningMutationUpdateTransactionArgs = {
  ids: Array<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type SigningRedirectionSession = {
  __typename?: 'SigningRedirectionSession';
  firstName: Scalars['String']['output'];
  jwt: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  sessionId: Scalars['String']['output'];
  signerId: Scalars['String']['output'];
  stepId: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type SigningTransaction = {
  __typename?: 'SigningTransaction';
  documents: Array<Document>;
  id: Scalars['String']['output'];
  signature?: Maybe<UniversignSignature>;
};

export enum SigningVendor {
  Luxtrust = 'luxtrust',
  Owp = 'owp',
  Universign = 'universign'
}

export type Sort = {
  field: Scalars['String']['input'];
  order: SortType;
};

export enum SortType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Source = {
  __typename?: 'Source';
  date?: Maybe<Scalars['Int']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type StartPreSubscriptionInput = {
  _externalClientID?: InputMaybe<Scalars['String']['input']>;
  distributionChannel: Array<Scalars['String']['input']>;
  product: Scalars['ID']['input'];
  remote: Scalars['Boolean']['input'];
  subscribers: Array<Scalars['ID']['input']>;
};

export type StartSubscriptionInput = {
  _externalClientID?: InputMaybe<Scalars['String']['input']>;
  actualOwner?: InputMaybe<SubscriptionOwnership>;
  distributionChannel: Array<Scalars['String']['input']>;
  platform?: InputMaybe<Scalars['String']['input']>;
  preSubscriptions?: InputMaybe<Array<PreSubscriptionInput>>;
  product: Scalars['ID']['input'];
  remote: Scalars['Boolean']['input'];
  subscribers: Array<Scalars['ID']['input']>;
};

export type StartWorkflowInput = {
  contentId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type StatisticPosition = {
  __typename?: 'StatisticPosition';
  _id: Scalars['ID']['output'];
  date: Scalars['Int']['output'];
  numberOfShares: Scalars['Float']['output'];
  outstandingAmount: Scalars['Float']['output'];
  shareClassCurrency: Scalars['String']['output'];
  valuationNAV: Scalars['Float']['output'];
  valuationNAVDate: Scalars['Int']['output'];
};

export type StopWorkflowInput = {
  comment: Scalars['String']['input'];
};

export type SubTask = {
  __typename?: 'SubTask';
  _id: Scalars['ID']['output'];
  affectedPerson?: Maybe<BaseUser>;
  createDate?: Maybe<Scalars['Int']['output']>;
  createPerson?: Maybe<BaseUser>;
  description?: Maybe<Scalars['String']['output']>;
  dueDate?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Typology>;
};

export type SubTaskInput = {
  affectedPerson?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['ClientCodeTypology']['input']>;
};

export type SubscribableProduct = Attachable & {
  __typename?: 'SubscribableProduct';
  _id: Scalars['ID']['output'];
  category?: Maybe<Scalars['String']['output']>;
  distributionChannels: Array<ProductDistributionChannel>;
  documents: Array<Document>;
  endDate: Scalars['Int']['output'];
  fees: UnderlyingFees;
  isin: Scalars['String']['output'];
  lastNav?: Maybe<Nav>;
  name: Scalars['String']['output'];
  nominal?: Maybe<Scalars['Float']['output']>;
  product: Underlying;
  shareClass: ShareClass;
  startDate: Scalars['Int']['output'];
  subscriptionDocumentConfigs: Array<SubscriptionDocumentConfig>;
  targetMarket?: Maybe<Scalars['JSON']['output']>;
  unsubscribableReasons: Array<Scalars['String']['output']>;
};


export type SubscribableProductDistributionChannelsArgs = {
  investorId: Scalars['ID']['input'];
};


export type SubscribableProductSubscriptionDocumentConfigsArgs = {
  lang: Scalars['String']['input'];
};

export type SubscribedClientKpi = {
  __typename?: 'SubscribedClientKpi';
  GrossValue: Scalars['Float']['output'];
  investors: Array<Investor>;
  netValue: Scalars['Float']['output'];
  numberOfProducts: Scalars['Int']['output'];
  performance: Scalars['Float']['output'];
  totalAmountInvested: Scalars['Float']['output'];
};

export type SubscribedProduct = {
  __typename?: 'SubscribedProduct';
  _id: Scalars['ID']['output'];
  composition_gross_value?: Maybe<Scalars['Float']['output']>;
  composition_investedAmount?: Maybe<Scalars['Float']['output']>;
  composition_quantity?: Maybe<Scalars['Float']['output']>;
  composition_value?: Maybe<Scalars['Float']['output']>;
  numberOfClients?: Maybe<Scalars['Int']['output']>;
  transaction_totalDistribution?: Maybe<Scalars['Float']['output']>;
  underlying_cotationCurrency?: Maybe<Scalars['String']['output']>;
  underlying_isin?: Maybe<Scalars['String']['output']>;
  underlying_name?: Maybe<Scalars['String']['output']>;
  underlying_nav?: Maybe<Scalars['Float']['output']>;
  underlying_nav_date?: Maybe<Scalars['Int']['output']>;
  underlying_nominal?: Maybe<Scalars['Float']['output']>;
  underlying_type?: Maybe<Scalars['String']['output']>;
};

export type SubscribedProductKpi = {
  __typename?: 'SubscribedProductKpi';
  numberOfSubscriptions: Scalars['Int']['output'];
  product: SubscribableProduct;
  totalAmount: Scalars['Float']['output'];
  totalOfShares: Scalars['Float']['output'];
};

export type SubscribedProductKpiList = {
  __typename?: 'SubscribedProductKpiList';
  subscribedProductKpis: Array<SubscribedProductKpi>;
  totalAmount: Scalars['Float']['output'];
};

export type Subscription = {
  __typename?: 'Subscription';
  generic?: Maybe<GenericSubscription>;
};


export type SubscriptionGenericArgs = {
  initial?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SubscriptionDocument = {
  __typename?: 'SubscriptionDocument';
  alerts: Array<RoleBasedAlert>;
  document: Document;
  subscribers: Array<Reference>;
};

export type SubscriptionDocumentConfig = {
  __typename?: 'SubscriptionDocumentConfig';
  documentType: Typology;
  signerRole: UserRoles;
};

export type SubscriptionDocumentValidation = {
  __typename?: 'SubscriptionDocumentValidation';
  date: Scalars['Int']['output'];
  profiles: Array<WorkflowTaskAuditUserProfile>;
  responsiblesEntityTypes?: Maybe<Array<UserEntityType>>;
  status: DocumentStatus;
  user: ExtendedUser;
};

export type SubscriptionDocumentsAuditReport = {
  __typename?: 'SubscriptionDocumentsAuditReport';
  generating: GeneratingDocumentSummary;
  supporting: SupportingDocumentSummary;
};

export type SubscriptionFee = {
  __typename?: 'SubscriptionFee';
  amount: Scalars['Float']['output'];
  rate: Scalars['Float']['output'];
  source: FeeSource;
  status: FeeStatus;
};

export type SubscriptionFeeInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  rate?: InputMaybe<Scalars['Float']['input']>;
  source?: InputMaybe<FeeSource>;
  status?: InputMaybe<FeeStatus>;
};

export type SubscriptionFees = {
  __typename?: 'SubscriptionFees';
  premium?: Maybe<SubscriptionFee>;
  subscriptionFee?: Maybe<SubscriptionFee>;
};

export type SubscriptionFeesInput = {
  premium?: InputMaybe<SubscriptionFeeInput>;
  subscriptionFee?: InputMaybe<SubscriptionFeeInput>;
};

export type SubscriptionFileKpi = {
  __typename?: 'SubscriptionFileKpi';
  amountsPerInvestor: Array<AmountPerInvestor>;
  amountsPerMonth: Array<AmountPerMonth>;
  amountsPerProduct: Array<AmountPerProduct>;
  subscribedProductKpiList?: Maybe<SubscribedProductKpiList>;
};


export type SubscriptionFileKpiAmountsPerInvestorArgs = {
  productId: Scalars['ID']['input'];
};

export type SubscriptionFileValidationAudit = {
  __typename?: 'SubscriptionFileValidationAudit';
  advisor: UserEntity;
  comment?: Maybe<Scalars['String']['output']>;
  date: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  status: SubscriptionFileValidationStatus;
};

export type SubscriptionFileValidationAuditInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  status: SubscriptionFileValidationStatus;
};

export enum SubscriptionFileValidationStatus {
  Flagged = 'FLAGGED',
  Rejected = 'REJECTED',
  Validated = 'VALIDATED'
}

export enum SubscriptionNomineeType {
  Pooled = 'pooled',
  Segregated = 'segregated'
}

export enum SubscriptionOwnership {
  OwnAccount = 'OWN_ACCOUNT',
  ThirdParty = 'THIRD_PARTY'
}

export enum SubscriptionStatus {
  Closed = 'CLOSED',
  InProgress = 'IN_PROGRESS',
  Validated = 'VALIDATED'
}

export enum SubscriptionSupport {
  AdministeredRegistered = 'AdministeredRegistered',
  PureRegistered = 'PureRegistered'
}

export type Supplier = {
  __typename?: 'Supplier';
  _id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  networkLinkId: Scalars['String']['output'];
  type: SupplierType;
};

export enum SupplierType {
  RoleAdvisor = 'RoleAdvisor',
  RoleAssetManager = 'RoleAssetManager',
  RoleBusinessProvider = 'RoleBusinessProvider',
  RoleDistributionPlatform = 'RoleDistributionPlatform',
  RoleDistributor = 'RoleDistributor',
  RoleDistributorGroup = 'RoleDistributorGroup',
  RoleInvestor = 'RoleInvestor'
}

export type SupportingDocument = {
  __typename?: 'SupportingDocument';
  document?: Maybe<Document>;
  type: Scalars['String']['output'];
  uploaded: Scalars['Boolean']['output'];
  validRequired: Scalars['Int']['output'];
  validated: Scalars['Int']['output'];
  validations: Array<SubscriptionDocumentValidation>;
};

export type SupportingDocumentSummary = {
  __typename?: 'SupportingDocumentSummary';
  supportingDocuments: Array<SupportingDocument>;
  uploaded: Scalars['Boolean']['output'];
  validRequired: Scalars['Int']['output'];
  validated: Scalars['Int']['output'];
};

export type Tag = {
  date?: Maybe<Scalars['Int']['output']>;
  label?: Maybe<Label>;
};

export type Task = {
  __typename?: 'Task';
  _id: Scalars['ID']['output'];
  affectedPerson?: Maybe<BaseUser>;
  chargePerson?: Maybe<BaseUser>;
  comments: Array<Comment>;
  createDate?: Maybe<Scalars['Int']['output']>;
  createPerson?: Maybe<BaseUser>;
  description?: Maybe<Scalars['String']['output']>;
  documents: Array<Document>;
  dueDate?: Maybe<Scalars['Int']['output']>;
  investor: BaseUserEntity;
  name?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<TaskPriority>;
  source?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Typology>;
  subTask?: Maybe<Array<SubTask>>;
  theme?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type TaskCreateInput = {
  affectedPerson?: InputMaybe<Scalars['ID']['input']>;
  chargePerson?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  documents?: InputMaybe<Array<DocumentInput>>;
  dueDate?: InputMaybe<Scalars['Int']['input']>;
  investor: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<TaskPriority>;
  source?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['ClientCodeTypology']['input']>;
  subTask?: InputMaybe<Array<SubTaskInput>>;
  theme?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export enum TaskPriority {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM'
}

export type TaskUpdateInput = {
  affectedPerson?: InputMaybe<Scalars['ID']['input']>;
  chargePerson?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['Int']['input']>;
  investor: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<TaskPriority>;
  source?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['ClientCodeTypology']['input']>;
  theme?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type TaxResidence = {
  __typename?: 'TaxResidence';
  LEI?: Maybe<Scalars['String']['output']>;
  country: Typology;
  noTINReason?: Maybe<Scalars['String']['output']>;
  tin?: Maybe<Scalars['String']['output']>;
};

export type TaxResidenceInput = {
  LEI?: InputMaybe<Scalars['String']['input']>;
  country: Scalars['ClientCodeTypology']['input'];
  noTINReason?: InputMaybe<Scalars['String']['input']>;
  tin?: InputMaybe<Scalars['String']['input']>;
};

export type Thread = {
  __typename?: 'Thread';
  _id: Scalars['ID']['output'];
  attachments: Array<Attachable>;
  creationDateTime?: Maybe<Scalars['Int']['output']>;
  lastMessage?: Maybe<Message>;
  messages: Array<Message>;
  subject?: Maybe<Scalars['String']['output']>;
  users: Array<BaseUser>;
};


export type ThreadMessagesArgs = {
  options?: InputMaybe<Options>;
};

export type ThreadCreated = {
  __typename?: 'ThreadCreated';
  sendMessage?: Maybe<Scalars['ID']['output']>;
  thread?: Maybe<Thread>;
};


export type ThreadCreatedSendMessageArgs = {
  attachments?: InputMaybe<Array<MessageAttachmentInput>>;
  content: Scalars['String']['input'];
};

export type ThreadInput = {
  attachments?: InputMaybe<Array<MessageAttachmentInput>>;
  peers?: InputMaybe<Array<Scalars['ID']['input']>>;
  subject: Scalars['String']['input'];
};

export type ThreadMutation = {
  __typename?: 'ThreadMutation';
  addMember?: Maybe<Scalars['Boolean']['output']>;
  attach?: Maybe<Scalars['Boolean']['output']>;
  create?: Maybe<ThreadCreated>;
  read?: Maybe<Scalars['Boolean']['output']>;
  received?: Maybe<Scalars['Boolean']['output']>;
  removeMember?: Maybe<Scalars['Boolean']['output']>;
  sendMessage?: Maybe<Scalars['ID']['output']>;
  updateThread?: Maybe<ThreadUpdated>;
};


export type ThreadMutationAddMemberArgs = {
  member: Scalars['ID']['input'];
};


export type ThreadMutationAttachArgs = {
  attachment?: InputMaybe<MessageAttachmentInput>;
};


export type ThreadMutationCreateArgs = {
  input: ThreadInput;
};


export type ThreadMutationRemoveMemberArgs = {
  member: Scalars['ID']['input'];
};


export type ThreadMutationSendMessageArgs = {
  attachments?: InputMaybe<Array<MessageAttachmentInput>>;
  content: Scalars['String']['input'];
};


export type ThreadMutationUpdateThreadArgs = {
  input: ThreadInput;
};

export type ThreadResult = SearchResult & {
  __typename?: 'ThreadResult';
  _id: Scalars['ID']['output'];
  index?: Maybe<Scalars['String']['output']>;
  lastMessage?: Maybe<Message>;
  score?: Maybe<Scalars['String']['output']>;
  source?: Maybe<ThreadSource>;
};

export type ThreadSource = {
  __typename?: 'ThreadSource';
  subject?: Maybe<Scalars['String']['output']>;
  users: Array<BaseUser>;
};

export type ThreadUpdated = {
  __typename?: 'ThreadUpdated';
  thread?: Maybe<Thread>;
};

export type TimeSlot = Attachable & {
  __typename?: 'TimeSlot';
  _id: Scalars['ID']['output'];
  address?: Maybe<Address>;
  addressDetails?: Maybe<Scalars['String']['output']>;
  appointment?: Maybe<Appointment>;
  endDatetime?: Maybe<Scalars['Int']['output']>;
  interactionType: AppointmentInteractionType;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  startDatetime?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<TimeSlotStatus>;
};

export type TimeSlotInput = {
  address?: InputMaybe<AddressInput>;
  addressDetails?: InputMaybe<Scalars['String']['input']>;
  endDatetime: Scalars['Int']['input'];
  interactionType: AppointmentInteractionType;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  startDatetime: Scalars['Int']['input'];
};

export type TimeSlotMutation = {
  __typename?: 'TimeSlotMutation';
  create?: Maybe<TimeSlot>;
  transition?: Maybe<Scalars['Boolean']['output']>;
};


export type TimeSlotMutationCreateArgs = {
  input: TimeSlotInput;
};


export type TimeSlotMutationTransitionArgs = {
  status: TimeSlotStatus;
};

export enum TimeSlotStatus {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Outdated = 'OUTDATED',
  Request = 'REQUEST',
  Unvalidate = 'UNVALIDATE'
}

export type TopicClassification = {
  __typename?: 'TopicClassification';
  predictedTag?: Maybe<Array<PredictedContentGroup>>;
  timeToRead?: Maybe<Scalars['Int']['output']>;
};

export type Translation = {
  __typename?: 'Translation';
  text?: Maybe<Scalars['String']['output']>;
  translationId?: Maybe<Scalars['String']['output']>;
};

export type Typology = {
  __typename?: 'Typology';
  clientCode: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  internalCode: Scalars['String']['output'];
  investorTypes?: Maybe<Array<Scalars['ClientCodeTypology']['output']>>;
  isPerMember?: Maybe<Scalars['Boolean']['output']>;
  isValidityDateRequired?: Maybe<Scalars['Boolean']['output']>;
  label: Scalars['String']['output'];
};

export enum TypologyType {
  Countries = 'countries',
  InvestorTypes = 'investorTypes',
  MlTfRisks = 'mlTfRisks',
  Regions = 'regions',
  TransactionTypes = 'transactionTypes'
}

export type Underlying = Attachable & Instrument & {
  __typename?: 'Underlying';
  _id: Scalars['ID']['output'];
  assetClass?: Maybe<Scalars['String']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  codeBloomberg?: Maybe<Scalars['String']['output']>;
  cotationCurrency?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  documents: Array<Document>;
  indexBloombergCode?: Maybe<Scalars['String']['output']>;
  isin?: Maybe<Scalars['String']['output']>;
  mnemo?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nominal?: Maybe<Scalars['Float']['output']>;
  peaFondsStatus?: Maybe<Scalars['String']['output']>;
  price?: Maybe<UnderlyingPrice>;
  prices?: Maybe<Array<UnderlyingPrice>>;
  rating?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Array<UnderlyingTag>>;
  tfe?: Maybe<Scalars['Float']['output']>;
  underlyingType?: Maybe<Scalars['String']['output']>;
};


export type UnderlyingPriceArgs = {
  at?: InputMaybe<Scalars['Int']['input']>;
};


export type UnderlyingPricesArgs = {
  options?: InputMaybe<Options>;
};

export type UnderlyingFees = {
  __typename?: 'UnderlyingFees';
  entranceFee?: Maybe<Scalars['Float']['output']>;
  redemptionFee?: Maybe<Scalars['Float']['output']>;
};

export type UnderlyingPrice = {
  __typename?: 'UnderlyingPrice';
  _id: Scalars['ID']['output'];
  aum?: Maybe<Scalars['Float']['output']>;
  date?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

export type UnderlyingTag = Tag & {
  __typename?: 'UnderlyingTag';
  date?: Maybe<Scalars['Int']['output']>;
  label?: Maybe<Label>;
};

export type UniversignRequestTransaction = {
  __typename?: 'UniversignRequestTransaction';
  _id: Scalars['ID']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type UniversignSignature = {
  __typename?: 'UniversignSignature';
  transactionId: Scalars['String']['output'];
  transactionInfo: UniversignTransactionInfo;
  transactionUrl: Scalars['String']['output'];
};

export type UniversignSignerInfo = {
  __typename?: 'UniversignSignerInfo';
  status: UniversignSignerInfoStatus;
  /**  Universign ID */
  universignId: Scalars['String']['output'];
  /**  signing URL */
  url: Scalars['String']['output'];
  /**  internal user */
  user: ExtendedUser;
};

export enum UniversignSignerInfoStatus {
  Accessed = 'accessed',
  Canceled = 'canceled',
  CodeSent = 'codeSent',
  Failed = 'failed',
  PendingIdDocs = 'pendingIdDocs',
  PendingValidation = 'pendingValidation',
  Ready = 'ready',
  Signed = 'signed',
  Waiting = 'waiting'
}

export type UniversignTransactionInfo = {
  __typename?: 'UniversignTransactionInfo';
  creationDate: Scalars['String']['output'];
  currentSigner: Scalars['Int']['output'];
  signerInfos: Array<UniversignSignerInfo>;
  status: UniversignTransactionInfoStatus;
};

export enum UniversignTransactionInfoStatus {
  Canceled = 'canceled',
  Completed = 'completed',
  Expired = 'expired',
  Failed = 'failed',
  Ready = 'ready'
}

export type UpdateAsUniquePrimaryContactInput = {
  userId: Scalars['ID']['input'];
};

export type UpdateDocumentInput = {
  expirationDate?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDocumentRequestInput = {
  name: Scalars['String']['input'];
  type: Scalars['ClientCodeTypology']['input'];
};

export type UpdateInvestorInput = {
  _internalClientID?: InputMaybe<Scalars['String']['input']>;
  addonCharacteristics?: InputMaybe<Scalars['AddonJson']['input']>;
  company?: InputMaybe<CompanyInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  opportunity?: InputMaybe<BusinessOpportunityInput>;
  status?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['ClientCodeTypology']['input']>;
};

export type UpdatePreSubscriptionOfProductSubscriptionInput = {
  addonCharacteristics?: InputMaybe<Scalars['AddonJson']['input']>;
  preSubscriptionId: Scalars['ID']['input'];
};

export type UpdateProductPreSubscriptionInput = {
  _externalClientID?: InputMaybe<Scalars['String']['input']>;
  _internalClientID?: InputMaybe<Scalars['String']['input']>;
  acaCommitmentRatio?: InputMaybe<Scalars['Float']['input']>;
  addonCharacteristics?: InputMaybe<Scalars['AddonJson']['input']>;
  amount?: InputMaybe<Scalars['Float']['input']>;
  handWrittenAmount?: InputMaybe<Scalars['String']['input']>;
  handWrittenNote?: InputMaybe<Scalars['String']['input']>;
  numberOfShares?: InputMaybe<Scalars['Float']['input']>;
  support?: InputMaybe<SubscriptionSupport>;
};

export type UpdateProductSubscriptionInput = {
  _externalClientID?: InputMaybe<Scalars['String']['input']>;
  _internalClientID?: InputMaybe<Scalars['String']['input']>;
  acaCommitmentRatio?: InputMaybe<Scalars['Float']['input']>;
  actualOwner?: InputMaybe<SubscriptionOwnership>;
  addonCharacteristics?: InputMaybe<Scalars['AddonJson']['input']>;
  amount?: InputMaybe<Scalars['Float']['input']>;
  fees?: InputMaybe<SubscriptionFeesInput>;
  handWrittenAmount?: InputMaybe<Scalars['String']['input']>;
  handWrittenNote?: InputMaybe<Scalars['String']['input']>;
  numberOfShares?: InputMaybe<Scalars['Float']['input']>;
  support?: InputMaybe<SubscriptionSupport>;
};

export type UpdateSubscriberProductPreSubscriptionInput = {
  addonCharacteristics?: InputMaybe<Scalars['AddonJson']['input']>;
};

export type UpdateSubscriberProductSubscriptionInput = {
  addonCharacteristics?: InputMaybe<Scalars['AddonJson']['input']>;
};

export type UpdateTaskInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  payload?: InputMaybe<Scalars['JSON']['input']>;
  taskId: Scalars['ID']['input'];
};

export type UpdateUserInput = {
  LinkedinId?: InputMaybe<Scalars['String']['input']>;
  PEP?: InputMaybe<PepInput>;
  _internalClientID?: InputMaybe<Scalars['String']['input']>;
  addonCharacteristics?: InputMaybe<Scalars['AddonJson']['input']>;
  address?: InputMaybe<Array<AddressInput>>;
  businessSector?: InputMaybe<Scalars['ClientCodeTypology']['input']>;
  children?: InputMaybe<Scalars['Int']['input']>;
  cityOfBirth?: InputMaybe<AddressInput>;
  civilStatus?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<CompanyInput>;
  contactTypes?: InputMaybe<Array<Scalars['ClientCodeTypology']['input']>>;
  dateOfBirth?: InputMaybe<Scalars['Int']['input']>;
  displayCurrency?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Array<EmailInput>>;
  facebookId?: InputMaybe<Scalars['String']['input']>;
  financialKnowledge?: InputMaybe<FinancialKnowledge>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hasAccessToReportingPower?: InputMaybe<Scalars['Boolean']['input']>;
  hasFileInputPower?: InputMaybe<Scalars['Boolean']['input']>;
  hasSignatoryPower?: InputMaybe<Scalars['Boolean']['input']>;
  isOnboarded?: InputMaybe<Scalars['Boolean']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  lastInteractionDate?: InputMaybe<Scalars['Int']['input']>;
  lastLoginDatetime?: InputMaybe<Scalars['Int']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  maidenName?: InputMaybe<Scalars['String']['input']>;
  newsAlert?: InputMaybe<Scalars['Boolean']['input']>;
  owner?: InputMaybe<Scalars['Boolean']['input']>;
  phone?: InputMaybe<Array<Scalars['String']['input']>>;
  primary?: InputMaybe<Scalars['Boolean']['input']>;
  profilePic?: InputMaybe<Scalars['String']['input']>;
  rgpdAgreed?: InputMaybe<Scalars['Boolean']['input']>;
  skypeId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<UserStatus>;
  taxResidence?: InputMaybe<Array<TaxResidenceInput>>;
  title?: InputMaybe<Scalars['String']['input']>;
  twitterId?: InputMaybe<Scalars['String']['input']>;
  whatsappId?: InputMaybe<Scalars['String']['input']>;
};

export type UserEntity = {
  _id: Scalars['ID']['output'];
  _internalClientID?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export enum UserEntityType {
  Am = 'AM',
  BusinessFacilitator = 'BUSINESS_FACILITATOR',
  Connector = 'CONNECTOR',
  Custodian = 'CUSTODIAN',
  Investor = 'INVESTOR',
  Platform = 'PLATFORM',
  ThirdParty = 'THIRD_PARTY'
}

export type UserError = {
  __typename?: 'UserError';
  errors: Array<InfoMessage>;
  userId: Scalars['ID']['output'];
};

export type UserInterest = {
  __typename?: 'UserInterest';
  bookmarked?: Maybe<Scalars['Boolean']['output']>;
  read?: Maybe<Scalars['Boolean']['output']>;
  useful?: Maybe<Scalars['Boolean']['output']>;
  /**  TODO: WTF ? */
  user?: Maybe<BaseUser>;
};

export type UserLink = {
  __typename?: 'UserLink';
  _id: Scalars['ID']['output'];
  user: BaseUser;
  userEntity: UserEntity;
};

export type UserMutation = {
  __typename?: 'UserMutation';
  acknowledgeComplianceChange: ExtendedUser;
  addDocument?: Maybe<ExtendedUser>;
  checkDigitalIdentity: DigitalIdentityChecking;
  /**
   *  add a new Screening on a user
   *  add a new MlTfRisk on a user
   */
  monitorComplianceSearch: ComplyAdvantageScreening;
  removeDocument?: Maybe<ExtendedUser>;
  sendEmail: ExtendedUser;
  update?: Maybe<ExtendedUser>;
  updateRoles?: Maybe<ExtendedUser>;
  validateIdentity: IdentityValidation;
};


export type UserMutationAddDocumentArgs = {
  input: DocumentInput;
};


export type UserMutationCheckDigitalIdentityArgs = {
  maidenName?: InputMaybe<Scalars['Boolean']['input']>;
};


export type UserMutationMonitorComplianceSearchArgs = {
  monitor: Scalars['Boolean']['input'];
};


export type UserMutationRemoveDocumentArgs = {
  docId: Scalars['ID']['input'];
};


export type UserMutationSendEmailArgs = {
  input: UserSendEmailInput;
};


export type UserMutationUpdateArgs = {
  input: UpdateUserInput;
};


export type UserMutationUpdateRolesArgs = {
  roles: Array<UserRoles>;
};


export type UserMutationValidateIdentityArgs = {
  input: ValidateIdentityInput;
  maidenName?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum UserRoles {
  Admin = 'ADMIN',
  Advisor = 'ADVISOR',
  BfProspect = 'BF_PROSPECT',
  BusinessFacilitator = 'BUSINESS_FACILITATOR',
  Custodian = 'CUSTODIAN',
  DataManager = 'DATA_MANAGER',
  Hrm = 'HRM',
  IfaAdmin = 'IFA_ADMIN',
  IfaFinance = 'IFA_FINANCE',
  IfaLegalRepresentative = 'IFA_LEGAL_REPRESENTATIVE',
  IfaMiddleOffice = 'IFA_MIDDLE_OFFICE',
  IfaRm = 'IFA_RM',
  Investor = 'INVESTOR',
  LegalRepresentative = 'LEGAL_REPRESENTATIVE',
  Me = 'ME',
  MiddleOffice = 'MIDDLE_OFFICE',
  Platform = 'PLATFORM',
  PlatformAdmin = 'PLATFORM_ADMIN',
  PlatformFinance = 'PLATFORM_FINANCE',
  PlatformLegalRepresentative = 'PLATFORM_LEGAL_REPRESENTATIVE',
  PlatformMiddleOffice = 'PLATFORM_MIDDLE_OFFICE',
  PlatformRm = 'PLATFORM_RM',
  Prospect = 'PROSPECT',
  Rcci = 'RCCI',
  Rm = 'RM',
  Sales = 'SALES',
  ThirdParty = 'THIRD_PARTY'
}

export type UserSendEmailInput = {
  fromUserId?: InputMaybe<Scalars['ID']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
  message: Scalars['String']['input'];
  templateName: Scalars['String']['input'];
  toUserId: Scalars['ID']['input'];
  variables?: InputMaybe<Scalars['JSON']['input']>;
};

export enum UserStatus {
  Busy = 'BUSY',
  Offline = 'OFFLINE',
  Online = 'ONLINE'
}

export type ValidateIdentityInput = {
  fileHashes: Array<Scalars['String']['input']>;
  idDocumentType: IdDocumentType;
};

export type ValidateTaskInput = {
  taskId: Scalars['ID']['input'];
};

export enum Video {
  All = 'all',
  NoVideo = 'noVideo',
  OnlyVideo = 'onlyVideo'
}

export type Workflow = {
  __typename?: 'Workflow';
  _id: Scalars['ID']['output'];
  audit: Array<WorkflowAudit>;
  auditReport?: Maybe<WorkflowAuditReport>;
  createdBy: BaseUser;
  createdDate?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  status: WorkflowStatus;
  tasksGroups: Array<WorkflowTasksGroup>;
  title: Scalars['String']['output'];
};

export type WorkflowAudit = {
  __typename?: 'WorkflowAudit';
  comment?: Maybe<Scalars['String']['output']>;
  date: Scalars['Int']['output'];
  extra?: Maybe<Scalars['JSON']['output']>;
  status: WorkflowStatus;
  user: BaseUser;
};

export type WorkflowAuditReport = {
  __typename?: 'WorkflowAuditReport';
  duration?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  taskGroups: Array<WorkflowTaskGroupAuditReport>;
  title: Scalars['String']['output'];
};

export type WorkflowMutation = {
  __typename?: 'WorkflowMutation';
  chaseUp: Workflow;
  refuseTask: Workflow;
  start: Workflow;
  stop: Workflow;
  updateTask: Workflow;
  validateTask: Workflow;
};


export type WorkflowMutationChaseUpArgs = {
  taskId: Scalars['ID']['input'];
};


export type WorkflowMutationRefuseTaskArgs = {
  input: RefuseTaskInput;
};


export type WorkflowMutationStartArgs = {
  input: StartWorkflowInput;
};


export type WorkflowMutationStopArgs = {
  input: StopWorkflowInput;
};


export type WorkflowMutationUpdateTaskArgs = {
  input: UpdateTaskInput;
};


export type WorkflowMutationValidateTaskArgs = {
  input: ValidateTaskInput;
};

export enum WorkflowStatus {
  Pending = 'pending',
  Refused = 'refused',
  Running = 'running',
  Skipped = 'skipped',
  Stopped = 'stopped',
  Validated = 'validated'
}

export type WorkflowTask = {
  __typename?: 'WorkflowTask';
  _id: Scalars['ID']['output'];
  audit: Array<WorkflowAudit>;
  comment?: Maybe<Scalars['String']['output']>;
  completedBy?: Maybe<BaseUser>;
  completedDate?: Maybe<Scalars['Int']['output']>;
  createdBy?: Maybe<BaseUser>;
  createdDate?: Maybe<Scalars['Int']['output']>;
  extra?: Maybe<Scalars['JSON']['output']>;
  name: Scalars['String']['output'];
  parameters?: Maybe<Scalars['JSON']['output']>;
  responsibles: Array<Reference>;
  responsiblesEntityTypes: Array<UserEntityType>;
  status: WorkflowStatus;
  title: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

export type WorkflowTaskAuditItemReport = {
  __typename?: 'WorkflowTaskAuditItemReport';
  duration?: Maybe<Scalars['Int']['output']>;
  endDate?: Maybe<Scalars['Int']['output']>;
  startDate: Scalars['Int']['output'];
  status: WorkflowStatus;
  user: BaseUser;
};

export type WorkflowTaskAuditReport = {
  __typename?: 'WorkflowTaskAuditReport';
  _id: Scalars['ID']['output'];
  audits: Array<WorkflowTaskAuditItemReport>;
  canChaseUp: Scalars['Boolean']['output'];
  duration?: Maybe<Scalars['Int']['output']>;
  endDate?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  responsibles: Array<BaseUser>;
  responsiblesEntityTypes?: Maybe<Array<UserEntityType>>;
  startDate?: Maybe<Scalars['Int']['output']>;
  status: WorkflowStatus;
  title: Scalars['String']['output'];
  userProfiles: Array<WorkflowTaskAuditUserProfile>;
};

export enum WorkflowTaskAuditUserProfile {
  Advisor = 'advisor',
  Ba = 'ba',
  Custodian = 'custodian',
  IfaAdmin = 'ifaAdmin',
  IfaAdvisor = 'ifaAdvisor',
  IfaFinance = 'ifaFinance',
  IfaMiddleOffice = 'ifaMiddleOffice',
  InvestorFileInput = 'investorFileInput',
  InvestorSignatory = 'investorSignatory',
  MiddleOffice = 'middleOffice',
  PlatformAdmin = 'platformAdmin',
  PlatformAdvisor = 'platformAdvisor',
  PlatformFinance = 'platformFinance',
  PlatformMiddleOffice = 'platformMiddleOffice',
  Rcci = 'rcci'
}

export type WorkflowTaskGroupAuditReport = {
  __typename?: 'WorkflowTaskGroupAuditReport';
  _id: Scalars['ID']['output'];
  duration?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  status: WorkflowStatus;
  tasks: Array<WorkflowTaskAuditReport>;
  title: Scalars['String']['output'];
};

export type WorkflowTasksGroup = {
  __typename?: 'WorkflowTasksGroup';
  _id: Scalars['ID']['output'];
  audit: Array<WorkflowAudit>;
  name: Scalars['String']['output'];
  parameters?: Maybe<Scalars['JSON']['output']>;
  status: WorkflowStatus;
  tasks: Array<WorkflowTask>;
  title: Scalars['String']['output'];
};

export type WorkflowTasksGroupLabel = {
  __typename?: 'WorkflowTasksGroupLabel';
  name: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type ContentFragment = { __typename?: 'Content', date?: number | null, title?: string | null, image?: string | null, preview?: string | null, content?: string | null, averageReadTime?: number | null, id: string, tags: Array<{ __typename?: 'ContentTag', label?: { __typename?: 'Label', name?: string | null, id: string } | null }>, authors: Array<{ __typename?: 'ContentAuthor', name?: string | null, email?: string | null }> };

export type DocumentFromAwFragment = { __typename?: 'Document', hash: string, name: string, publicationDate?: number | null, id: string, type?: { __typename?: 'Typology', label: string, code: string } | null };

export type DocumentRequestFragment = { __typename?: 'DocumentRequest', name?: string | null, creationDate?: number | null, id: string, message: Array<{ __typename?: 'MessageRequest', value?: string | null }>, sender: { __typename?: 'BaseUser', firstName?: string | null, lastName?: string | null }, type: { __typename?: 'Typology', code: string } };

export type InvestorAddDocMutationVariables = Exact<{
  input: DocumentInput;
  investorId: Scalars['ID']['input'];
}>;


export type InvestorAddDocMutation = { __typename?: 'Mutation', investor?: { __typename?: 'InvestorMutation', addDocument?: { __typename?: 'Investor', id: string } | null } | null };

export type InvestorRemoveDocMutationVariables = Exact<{
  investorId: Scalars['ID']['input'];
  documentId: Scalars['ID']['input'];
}>;


export type InvestorRemoveDocMutation = { __typename?: 'Mutation', investor?: { __typename?: 'InvestorMutation', removeDocument?: { __typename?: 'Investor', name: string } | null } | null };

export type UpdateDocStatusMutationVariables = Exact<{
  requestId: Scalars['ID']['input'];
}>;


export type UpdateDocStatusMutation = { __typename?: 'Mutation', documentRequest?: { __typename?: 'DocumentRequestMutation', updateStatus?: { __typename?: 'DocumentRequest', type: { __typename?: 'Typology', internalCode: string } } | null } | null };

export type GetArticleFromAwQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetArticleFromAwQuery = { __typename?: 'Query', article?: { __typename?: 'Content', date?: number | null, title?: string | null, image?: string | null, preview?: string | null, content?: string | null, averageReadTime?: number | null, id: string, tags: Array<{ __typename?: 'ContentTag', label?: { __typename?: 'Label', name?: string | null, id: string } | null }>, authors: Array<{ __typename?: 'ContentAuthor', name?: string | null, email?: string | null }> } | null };

export type GetInvestorDocumentRequestsFromAwQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetInvestorDocumentRequestsFromAwQuery = { __typename?: 'Query', me: { __typename?: 'ConnectedUser', investorByAfId?: { __typename?: 'Investor', documentRequests: Array<{ __typename?: 'DocumentRequest', name?: string | null, creationDate?: number | null, id: string, message: Array<{ __typename?: 'MessageRequest', value?: string | null }>, sender: { __typename?: 'BaseUser', firstName?: string | null, lastName?: string | null }, type: { __typename?: 'Typology', code: string } }> } | null } };

export type GetInvestorDocumentsFromAwQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetInvestorDocumentsFromAwQuery = { __typename?: 'Query', me: { __typename?: 'ConnectedUser', investorByAfId?: { __typename?: 'Investor', id: string, documents: Array<{ __typename?: 'Document', hash: string, name: string, publicationDate?: number | null, id: string, type?: { __typename?: 'Typology', label: string, code: string } | null }>, documentRequests: Array<{ __typename?: 'DocumentRequest', name?: string | null, creationDate?: number | null, id: string, message: Array<{ __typename?: 'MessageRequest', value?: string | null }>, sender: { __typename?: 'BaseUser', firstName?: string | null, lastName?: string | null }, type: { __typename?: 'Typology', code: string } }>, subscriptions: Array<{ __typename?: 'ProductSubscription', date?: number | null, amount: number, id: string, product: { __typename?: 'SubscribableProduct', name: string, product: { __typename?: 'Underlying', cotationCurrency?: string | null } }, documentChecklist: Array<{ __typename?: 'OnboardingDocument', documentType: { __typename?: 'Typology', code: string } }>, subscriptionDocuments: Array<{ __typename?: 'SubscriptionDocument', document: { __typename?: 'Document', hash: string, name: string, publicationDate?: number | null, id: string, type?: { __typename?: 'Typology', label: string, code: string } | null } }> }> } | null } };

export type GetNewsFromAwQueryVariables = Exact<{
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  selectedKind: Scalars['String']['input'];
  tagIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type GetNewsFromAwQuery = { __typename?: 'Query', news?: Array<{ __typename?: 'Content', date?: number | null, title?: string | null, image?: string | null, preview?: string | null, content?: string | null, averageReadTime?: number | null, id: string, tags: Array<{ __typename?: 'ContentTag', label?: { __typename?: 'Label', name?: string | null, id: string } | null }>, authors: Array<{ __typename?: 'ContentAuthor', name?: string | null, email?: string | null }> }> | null };

export const ContentFragmentDoc = gql`
    fragment content on Content {
  id: _id
  date
  title
  image
  tags {
    label {
      id: _id
      name
    }
  }
  authors {
    name
    email
  }
  preview
  content
  averageReadTime
}
    `;
export const DocumentFromAwFragmentDoc = gql`
    fragment documentFromAw on Document {
  id: _id
  hash
  type {
    code: internalCode
    label
  }
  name
  publicationDate
}
    `;
export const DocumentRequestFragmentDoc = gql`
    fragment documentRequest on DocumentRequest {
  id: _id
  name
  message {
    value
  }
  sender {
    firstName
    lastName
  }
  type {
    code: internalCode
  }
  creationDate
}
    `;
export const InvestorAddDocDocument = gql`
    mutation InvestorAddDoc($input: DocumentInput!, $investorId: ID!) {
  investor(id: $investorId) {
    addDocument(input: $input) {
      id: _id
    }
  }
}
    `;

export function useInvestorAddDocMutation() {
  return Urql.useMutation<InvestorAddDocMutation, InvestorAddDocMutationVariables>(InvestorAddDocDocument);
};
export const InvestorRemoveDocDocument = gql`
    mutation InvestorRemoveDoc($investorId: ID!, $documentId: ID!) {
  investor(id: $investorId) {
    removeDocument(docId: $documentId) {
      name
    }
  }
}
    `;

export function useInvestorRemoveDocMutation() {
  return Urql.useMutation<InvestorRemoveDocMutation, InvestorRemoveDocMutationVariables>(InvestorRemoveDocDocument);
};
export const UpdateDocStatusDocument = gql`
    mutation UpdateDocStatus($requestId: ID!) {
  documentRequest {
    updateStatus(requestId: $requestId) {
      type {
        internalCode
      }
    }
  }
}
    `;

export function useUpdateDocStatusMutation() {
  return Urql.useMutation<UpdateDocStatusMutation, UpdateDocStatusMutationVariables>(UpdateDocStatusDocument);
};
export const GetArticleFromAwDocument = gql`
    query GetArticleFromAw($id: ID!) {
  article: content(id: $id) {
    ...content
  }
}
    ${ContentFragmentDoc}`;

export function useGetArticleFromAwQuery(options: Omit<Urql.UseQueryArgs<never, GetArticleFromAwQueryVariables>, 'query'>) {
  return Urql.useQuery<GetArticleFromAwQuery, GetArticleFromAwQueryVariables>({ query: GetArticleFromAwDocument, ...options });
};
export const GetInvestorDocumentRequestsFromAwDocument = gql`
    query GetInvestorDocumentRequestsFromAw($id: String!) {
  me {
    investorByAfId(id: $id) {
      documentRequests(status: PENDING) {
        ...documentRequest
      }
    }
  }
}
    ${DocumentRequestFragmentDoc}`;

export function useGetInvestorDocumentRequestsFromAwQuery(options: Omit<Urql.UseQueryArgs<never, GetInvestorDocumentRequestsFromAwQueryVariables>, 'query'>) {
  return Urql.useQuery<GetInvestorDocumentRequestsFromAwQuery, GetInvestorDocumentRequestsFromAwQueryVariables>({ query: GetInvestorDocumentRequestsFromAwDocument, ...options });
};
export const GetInvestorDocumentsFromAwDocument = gql`
    query GetInvestorDocumentsFromAw($id: String!) {
  me {
    investorByAfId(id: $id) {
      id: _id
      documents {
        ...documentFromAw
      }
      documentRequests(status: PENDING) {
        ...documentRequest
      }
      subscriptions(status: [VALIDATED]) {
        id: _id
        date
        amount
        product {
          name
          product {
            cotationCurrency
          }
        }
        documentChecklist {
          documentType {
            code: internalCode
          }
        }
        subscriptionDocuments {
          document {
            ...documentFromAw
          }
        }
      }
    }
  }
}
    ${DocumentFromAwFragmentDoc}
${DocumentRequestFragmentDoc}`;

export function useGetInvestorDocumentsFromAwQuery(options: Omit<Urql.UseQueryArgs<never, GetInvestorDocumentsFromAwQueryVariables>, 'query'>) {
  return Urql.useQuery<GetInvestorDocumentsFromAwQuery, GetInvestorDocumentsFromAwQueryVariables>({ query: GetInvestorDocumentsFromAwDocument, ...options });
};
export const GetNewsFromAwDocument = gql`
    query GetNewsFromAw($pageSize: Int, $selectedKind: String!, $tagIds: [ID!]!) {
  news: contents(
    kind: $selectedKind
    options: {limit: $pageSize}
    labels: {ids: $tagIds, mode: ALL}
  ) {
    ...content
  }
}
    ${ContentFragmentDoc}`;

export function useGetNewsFromAwQuery(options: Omit<Urql.UseQueryArgs<never, GetNewsFromAwQueryVariables>, 'query'>) {
  return Urql.useQuery<GetNewsFromAwQuery, GetNewsFromAwQueryVariables>({ query: GetNewsFromAwDocument, ...options });
};